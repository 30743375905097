import { App } from "app-module";
import graphql from "babel-plugin-relay/macro";
import { addClassesIf } from "common/utils/web/css";
import {
  Input,
  TableFooter,
  TableFooterReducedPadding,
} from "components/DaStyledElements";
import * as Paginate from "components/DaStyledElements/Paginator";
import usePaginationState, {
  ActionType,
  SortOrder,
} from "components/DaStyledElements/Paginator/usePaginationState";
import { GenericPageFallback } from "components/GenericPageFallback";
import { AlertsContextProvider } from "contexts/AlertsContext";
import { ascend, descend, isEmpty, sort, splitEvery } from "ramda";
import React, { Suspense, useMemo } from "react";
import { RelayEnvironmentProvider, useLazyLoadQuery } from "react-relay/hooks";
import { react2angular } from "react2angular";
import { objectSearch } from "search-filterer";
import { toDealerId } from "securecom-graphql/client";
import styled from "styled-components";
import { CustomRolesDealerQuery } from "./__generated__/CustomRolesDealerQuery.graphql";
import { CustomRolesQuery } from "./__generated__/CustomRolesQuery.graphql";

enum SortKey {
  Email,
  CustomRole,
}

const EmailLink = styled.a`
  border: none;
  background-color: transparent;
  color: var(--color-primary);
  color: #3d85a3;
  font-weight: 400;
  transition: all 200ms;

  &:hover {
    color: var(--color-primary-800);
  }
`;

export const TableHeaderSortingTextLeft = styled.th`
  cursor: pointer;
  color: #888 !important;
  text-align: left !important;
`;

const CustomRolesData: React.FC<
  React.PropsWithChildren<{ dealerId: string; $rootScope: any }>
> = ({ dealerId, $rootScope }) => {
  const data = useLazyLoadQuery<CustomRolesQuery>(
    graphql`
      query CustomRolesQuery($dealerId: String!) {
        dealerCustomRolePersonnel(dealer_id: $dealerId) {
          id
          email
          role
          customRoleId
          customRoleName
          person_email
        }
      }
    `,
    { dealerId: dealerId },
    { fetchPolicy: "network-only" }
  );

  const dealerData = useLazyLoadQuery<CustomRolesDealerQuery>(
    graphql`
      query CustomRolesDealerQuery($dealerId: ID!) {
        dealer: node(id: $dealerId) {
          ... on Dealer {
            vernaculars {
              original
              replacement
            }
          }
        }
      }
    `,
    {
      dealerId: String(toDealerId(dealerId)),
    }
  );
  const customRoleReplacement =
    dealerData.dealer?.vernaculars?.find(
      (vern) => vern?.original === "custom roles"
    )?.replacement || "Role";

  const [paginationState, dispatch] = usePaginationState({
    sortOrder: SortOrder.Ascending,
    sortKey: SortKey.Email,
    pageSize: 25,
  });
  const temp = Object.values(data.dealerCustomRolePersonnel!);

  // break these out so changing page size doesn't cause it to re-search etc...
  const searchedSummaries = React.useMemo(
    () => objectSearch(paginationState.search, ["person_email"], temp),
    [temp, paginationState.search]
  );

  const sortedSummaries = React.useMemo(
    () =>
      sort(
        (paginationState.sortOrder === SortOrder.Ascending ? ascend : descend)(
          (summary) => {
            switch (paginationState.sortKey) {
              case SortKey.Email:
                return summary?.person_email.toLowerCase();
              case SortKey.CustomRole:
                return summary?.role.toLowerCase();
              default:
                return summary?.person_email.toLowerCase();
            }
          }
        ),
        searchedSummaries
      ),
    [paginationState.sortKey, paginationState.sortOrder, searchedSummaries]
  );

  const pagedSummaries = React.useMemo(
    () =>
      splitEvery(paginationState.pageSize, sortedSummaries)[
        paginationState.currentPage - 1
      ] ?? [],
    [paginationState.currentPage, paginationState.pageSize, sortedSummaries]
  );

  $rootScope.eligiblePersonnel = sortedSummaries;

  $rootScope.$applyAsync();
  const maxPage = Math.ceil(sortedSummaries.length / paginationState.pageSize);

  return data.dealerCustomRolePersonnel ? (
    <>
      <label className="sr-only" htmlFor="search-input">
        Search
      </label>
      <Input.FullWidthTableSearch
        id="search-input"
        value={paginationState.search}
        onChange={(search) => {
          dispatch({
            type: ActionType.SetSearch,
            search,
          });
        }}
      />

      <div className="table-responsive">
        <table className="table table-striped table-fixed--not-mobile mt-lg">
          <thead>
            <tr>
              <TableHeaderSortingTextLeft></TableHeaderSortingTextLeft>

              <TableHeaderSortingTextLeft
                className={addClassesIf(
                  [
                    [paginationState.sortKey === SortKey.Email, "is-active"],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.Email) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.Email,
                    });
                  }
                }}
              >
                Email
              </TableHeaderSortingTextLeft>

              <TableHeaderSortingTextLeft
                className={addClassesIf(
                  [
                    [
                      paginationState.sortKey === SortKey.CustomRole,
                      "is-active",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Descending,
                      "tablesort-desc",
                    ],
                    [
                      paginationState.sortOrder === SortOrder.Ascending,
                      "tablesort-asc",
                    ],
                  ],
                  "tablesort-sortable"
                )}
                onClick={() => {
                  if (paginationState.sortKey === SortKey.CustomRole) {
                    dispatch({
                      type: ActionType.SetSortOrder,
                      sortOrder:
                        paginationState.sortOrder === SortOrder.Ascending
                          ? SortOrder.Descending
                          : SortOrder.Ascending,
                    });
                  } else {
                    dispatch({
                      type: ActionType.SetSortKey,
                      sortKey: SortKey.CustomRole,
                    });
                  }
                }}
              >
                Current {customRoleReplacement}
              </TableHeaderSortingTextLeft>
            </tr>
          </thead>

          <tbody>
            {!isEmpty(pagedSummaries) ? (
              pagedSummaries.map((personnel) => (
                <tr key={personnel?.id}>
                  <td>
                    <label
                      className="checkbox-inline c-checkbox"
                      htmlFor={"assignCheck" + personnel?.id}
                    >
                      <input
                        id={"assignCheck" + personnel?.id}
                        name={"assignCheck" + personnel?.id}
                        type="checkbox"
                        defaultChecked={$rootScope.assignedRoleOnLoad.some(
                          (person: { user: { id: string | undefined } }) =>
                            person.user?.id == personnel?.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            $rootScope.assignRole.push(personnel);
                            $rootScope.removeRole =
                              $rootScope.removeRole.filter(
                                (person: { id: string | undefined }) =>
                                  person.id != personnel?.id
                              );
                            $rootScope.$applyAsync();
                          } else {
                            $rootScope.assignRole =
                              $rootScope.assignRole.filter(
                                (person: { id: string | undefined }) =>
                                  person.id != personnel?.id
                              );
                            $rootScope.removeRole.push(personnel);
                            $rootScope.$applyAsync();
                          }
                        }}
                      />
                      <span className="icon-checkmark"></span>
                    </label>
                  </td>
                  <td>
                    {$rootScope.canEditPersonnel ? (
                      <EmailLink
                        href={
                          "#/app/dealers/" +
                          dealerId +
                          "/personnel/" +
                          personnel?.id +
                          "/edit"
                        }
                      >
                        {personnel?.person_email}
                      </EmailLink>
                    ) : (
                      <EmailLink>{personnel?.person_email}</EmailLink>
                    )}
                  </td>
                  <td className="text-right">
                    {renderRoleName(
                      personnel?.role!,
                      personnel?.customRoleName!
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4}>No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <TableFooterReducedPadding>
        <TableFooter.Left></TableFooter.Left>
        <TableFooter.Center>
          <Paginate.Paginator
            state={paginationState}
            dispatch={dispatch}
            maxPage={maxPage}
          />
        </TableFooter.Center>
        <TableFooter.Right>
          {" "}
          <Paginate.ItemsPerPage state={paginationState} dispatch={dispatch} />
        </TableFooter.Right>
      </TableFooterReducedPadding>
    </>
  ) : null;
};

const CustomRolesRoot: React.FC<React.PropsWithChildren<any>> = ({
  RelayService,
  $state,
  $rootScope,
}: any) => {
  const environment = useMemo(
    () => RelayService.getEnvironment(),
    [RelayService]
  );
  return (
    <RelayEnvironmentProvider environment={environment as any}>
      <Suspense fallback={<GenericPageFallback />}>
        <AlertsContextProvider $rootScope={$rootScope}>
          <CustomRolesData
            dealerId={$state.params.dealer_id}
            $rootScope={$rootScope}
          />
        </AlertsContextProvider>
      </Suspense>
    </RelayEnvironmentProvider>
  );
};

export const dangerouslyAddToApp = () => {
  App.component(
    "customRoles",
    react2angular(CustomRolesRoot, [], ["RelayService", "$state", "$rootScope"])
  );
};

function renderRoleName(value: string, custom: string) {
  if (custom != null) {
    value = custom;
  }
  switch (value) {
    case null:
      return "Standard";
    case "admin":
      return "Administrator";
    case "technician":
      return "Technician";
    case "support":
      return "Customer Support";
    case "operator":
      return "Operator";
    case "sales_person":
      return "Sales Person";
    case "sales_manager":
      return "Sales Manager";
    case "video_verifier":
      return "Video Verifier";
    case "accountant":
      return "Accountant";
    default:
      return value;
  }
}
