import DaColors from "app/common/DaColors";
import graphql from "babel-plugin-relay/macro";
import Button from "components/Button";
import {
  CleanAddButton,
  CleanPageHeader,
  CleanTable,
} from "components/DaStyledElements";
import CleanConfirmingDelete from "components/DaStyledElements/CleanConfirmingDelete";
import CleanPageSubHeader from "components/DaStyledElements/CleanPageSubHeader";
import Icon from "components/Icon";
import useToasts from "hooks/use-toasts";
import React from "react";
import { useLazyLoadQuery, useMutation } from "react-relay/hooks";
import {
  asString,
  SystemType,
  toControlSystemId,
} from "securecom-graphql/client";
import styled from "styled-components";
import { GroupsListDeleteGroupMutation } from "./__generated__/GroupsListDeleteGroupMutation.graphql";
import { GroupsListQuery } from "./__generated__/GroupsListQuery.graphql";
import { GroupsListRefreshGroupsMutation } from "./__generated__/GroupsListRefreshGroupsMutation.graphql";

const GroupActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
`;
const PrimaryIcon = styled(Icon)`
  color: ${DaColors.Primary500};
`;

type GroupActionButtonsProps = {
  customerId: string;
  controlSystemId: string;
  groupNumber: string;
  errorMessage: string;
};

const GroupActionButtons = ({
  customerId,
  controlSystemId,
  groupNumber,
  errorMessage,
}: GroupActionButtonsProps) => {
  const [createToast] = useToasts();

  const [deleteGroup, isDeletingGroup] =
    useMutation<GroupsListDeleteGroupMutation>(
      graphql`
        mutation GroupsListDeleteGroupMutation(
          $systemId: ID!
          $groupNumber: String!
        ) {
          deleteGroup(systemId: $systemId, profileNumber: $groupNumber) {
            __typename
            ... on DeleteGroupSuccessResponse {
              system {
                id
                groupsConnection {
                  edges {
                    node {
                      id
                      number
                      name
                    }
                  }
                }
              }
            }
            ... on DeleteGroupErrorResponse {
              message
            }
          }
        }
      `
    );

  const onDelete = () => {
    const successMessage = `Group ${groupNumber} was deleted from the system`;
    deleteGroup({
      variables: {
        systemId: asString(toControlSystemId(controlSystemId)),
        groupNumber: groupNumber,
      },
      onCompleted: (response) => {
        if (response.deleteGroup.__typename === "DeleteGroupErrorResponse") {
          createToast("error", response.deleteGroup.message ?? errorMessage);
        } else {
          createToast("success", successMessage);
        }
      },
      onError: () => {
        createToast("error", errorMessage);
      },
    });
  };

  return (
    <GroupActionButtonsWrapper>
      <a
        href={`/#/app/customers/${customerId}/control_systems/${controlSystemId}/panels/${controlSystemId}/programming/groups/${groupNumber}/edit`}
      >
        <PrimaryIcon name="settings" size="2.2rem" />
      </a>
      <CleanConfirmingDelete
        clickReceiver={(onClick) => (
          <Icon
            name="trash"
            size="2.2rem"
            color={DaColors.Failure500}
            onClick={onClick}
          />
        )}
        onConfirm={onDelete}
        message="Are you sure you want to delete this Group?"
      />
    </GroupActionButtonsWrapper>
  );
};

type GroupsProps = {
  customerId: string;
  controlSystemId: string;
  canEdit: boolean;
};

const Groups = ({ customerId, controlSystemId, canEdit }: GroupsProps) => {
  const [createToast] = useToasts();

  const data = useLazyLoadQuery<GroupsListQuery>(
    graphql`
      query GroupsListQuery($systemId: ID!) {
        system: node(id: $systemId) {
          ... on ControlSystem {
            panel {
              systemOptions {
                ... on Xt75SystemOptions {
                  systemType
                }
              }
            }
            id
            name
            groupsConnection {
              totalCount
              edges {
                node {
                  id
                  number
                  name
                }
              }
            }
            customer {
              dealer {
                vernaculars {
                  original
                  replacement
                }
              }
            }
          }
        }
      }
    `,
    { systemId: asString(toControlSystemId(controlSystemId)) },
    { fetchPolicy: "network-only" }
  );
  const groups =
    data.system?.groupsConnection?.edges.map((edge) => edge.node) ?? [];
  const systemReplacement = data.system?.customer?.dealer.vernaculars.find(
    (v) => v?.original === "systems"
  )?.replacement;

  const errorMessage = `Error Occurred when attempting to delete a group from the ${
    systemReplacement ?? "system"
  }`;
  const successRetrieveMessage = `Group programming retrieved from the ${
    systemReplacement ?? "system"
  }`;
  const errorRetrieveMessage = "Unable to retrieve groups.";

  const [refreshGroups, isRefreshingGroups] =
    useMutation<GroupsListRefreshGroupsMutation>(
      graphql`
        mutation GroupsListRefreshGroupsMutation($systemId: ID!) {
          refreshGroups(systemId: $systemId) {
            ... on RefreshGroupsSuccessResponse {
              __typename
              system {
                groupsConnection {
                  edges {
                    node {
                      id
                      number
                      name
                    }
                  }
                }
              }
            }
            ... on RefreshGroupsErrorResponse {
              __typename
              message
            }
          }
        }
      `
    );

  const onRetrieve = () => {
    refreshGroups({
      variables: {
        systemId: asString(toControlSystemId(controlSystemId)),
      },
      onCompleted: (response) => {
        if (
          response.refreshGroups.__typename === "RefreshGroupsErrorResponse"
        ) {
          createToast(
            "error",
            errorRetrieveMessage + ` ${response.refreshGroups.message}`
          );
        } else {
          createToast("success", successRetrieveMessage);
        }
      },
      onError: (error) => {
        createToast("error", errorRetrieveMessage + ` ${error}`);
      },
    });
  };

  return (
    <>
      <CleanPageHeader
        leftContent={<h1>{data.system?.name}</h1>}
        rightContent={
          <Button onClick={onRetrieve} disabled={isRefreshingGroups}>
            Retrieve From {systemReplacement ?? "System"}
          </Button>
        }
      />
      <CleanPageSubHeader
        content={
          <>
            <h2>Groups</h2>
            {groups.length < 10 &&
            data.system?.panel?.systemOptions?.systemType ===
              SystemType.AREA ? (
              <CleanAddButton
                onClick={() =>
                  location.assign(
                    `/#/app/customers/${customerId}/control_systems/${controlSystemId}/panels/${controlSystemId}/programming/groups/new`
                  )
                }
              />
            ) : null}
          </>
        }
      />

      <CleanTable>
        <thead>
          <tr>
            <th>Number</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          {groups
            .sort((a, b) =>
              a.number && b.number ? Number(a.number) - Number(b.number) : 0
            )
            .map((group) => {
              return (
                <tr key={group.id}>
                  <td>{group.number?.padStart(3, "0")}</td>
                  <td>{group.name}</td>
                  <td>
                    {canEdit &&
                      group.number &&
                      data.system?.panel?.systemOptions?.systemType ===
                        SystemType.AREA && (
                        <GroupActionButtons
                          customerId={customerId}
                          controlSystemId={controlSystemId}
                          groupNumber={group.number}
                          errorMessage={errorMessage}
                        />
                      )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </CleanTable>
    </>
  );
};

export default Groups;
