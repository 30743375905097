App.controller("SearchPageCtrl", [
  "$rootScope",
  "$scope",
  "$state",
  "parameter",
  "criteria",
  "SearchDataService",
  "UserService",
  "ResetPasswordAPI",
  "UsersV2API",
  "DealerService",
  "$window",
  "Customer",
  "$timeout",
  "focus",
  function (
    $rootScope,
    $scope,
    $state,
    parameter,
    criteria,
    SearchDataService,
    UserService,
    ResetPasswordAPI,
    UsersV2API,
    DealerService,
    $window,
    Customer,
    $timeout,
    focus
  ) {
    /*Scope Variable*/
    $scope.UserService = UserService;
    $scope.search = { criteria: criteria, parameter: parameter };
    $scope.error = {};
    $scope.results = [];
    $scope.resultsFound = {};
    $scope.isCustomerSearch = {};
    $scope.isDealerSearch = {};
    $scope.isVideoSearch = {};
    $scope.isThirdPartyVideoDeviceSearch = {};
    $scope.isSystemsSearch = {};
    $scope.isUsersSearch = {};
    $scope.resetAccount = {};
    $scope.resetSuccess = {};
    $scope.sortType = {};
    $scope.searchType = criteria.toLowerCase();
    $scope.pagination = {};
    $scope.pagination.currentPage = 1;

    // $scope functions
    $scope.doSearch = doSearch;
    $scope.resetPassword = resetPassword;
    $scope.resetPage = resetPage;
    $scope.selectDealer = selectDealer;
    $scope.selectCustomer = selectCustomer;
    $scope.selectUser = selectUser;
    $scope.selectVideoDevice = selectVideoDevice;
    $scope.selectThirdPartyVideoDevice = selectThirdPartyVideoDevice;
    $scope.selectControlSystem = selectControlSystem;
    $scope.selectXVDevice = selectXVDevice;
    $scope.selectXVControlSystem = selectXVControlSystem;
    $scope.addDealer = addDealer;

    $scope.checkTitle = function (searchTitle) {
      const systemReplacement =
        UserService.dealerInfo.vernaculars.systems.replacement;
      const customerReplacement =
        UserService.dealerInfo.vernaculars.customers.replacement;
      const appUsersReplacement =
        UserService.dealerInfo.vernaculars.appUsers.replacement;

      switch (searchTitle.toLowerCase()) {
        case "systems":
          return systemReplacement || "Systems";

        case "users":
          if (appUsersReplacement) {
            return appUsersReplacement;
          }
          if (UserService.displayDealerOptions) {
            return "App Users";
          }
          return "Users";

        case "customers":
          return customerReplacement || "Customers";

        default:
          return searchTitle;
      }
    };

    // Focus on search bar
    $timeout(function () {
      focus("queryInput");
    }, 300);

    function doSearch() {
      if (DoesNestedPropertyExist($scope, "search.parameter.length")) {
        checkForCriteriaChange();
        switch ($scope.search.criteria.toUpperCase()) {
          case "CUSTOMERS":
            customerSearchInit($scope.search.parameter);
            break;
          case "USERS":
            usersSearchInit($scope.search.parameter);
            break;
          case "SYSTEMS":
            systemsSearchInit($scope.search.parameter);
            break;
          case "DEALERS":
            dealerSearchInit($scope.search.parameter);
            break;
          case "VIDEO DEVICES":
            videoDevicesSearchInit($scope.search.parameter);
            break;
          case "SECURECOM VIDEO DOORBELL":
            thirdPartyVideoDevicesSearchInit($scope.search.parameter);
            break;
          case "XV SERIES MAC ADDRESS":
            alarmVisionSearchInit($scope.search.parameter);
            break;
          default:
            customerSearchInit($scope.search.parameter);
            break;
        }
        $scope.searchType = $scope.search.criteria.toLowerCase();
      } else {
        resetResults();
      }
    }

    function resetPassword(user) {
      if (user.hasOwnProperty("accessible_type")) {
        if (
          user["accessible_type"] === "Dealer" ||
          user["accessible_type"] === "Supervisor"
        ) {
          UsersV2API.resetAccessEmail(
            { email: user.email },
            function () {
              // Success
              $scope.resetAccount = user.email;
              $scope.resetSuccess = true;
            },
            function (error) {
              // Error
              $scope.error = error;
            }
          );
        } else if (user["accessible_type"] === "Customer") {
          UsersV2API.resetAccess(
            { user_id: user.id },
            function () {
              $scope.resetAccount = user.email;
              $scope.resetSuccess = true;
            },
            function (error) {
              $scope.error = error;
            }
          );
        }
      }
    }

    function resetPage() {
      $scope.pagination.currentPage = 1;
    }

    function resetResults() {
      $scope.resultsFound = {};
      $scope.results = [];
    }

    function selectDealer(dealer) {
      UserService.setSelectedDealer(
        new DealerService({ dealer_id: dealer.id })
      );
    }

    function selectCustomer(customer) {
      UserService.setSelectedDealer(
        new DealerService({ dealer_id: customer.dealer_id })
      )
        .then(function () {
          $state.go("app.customers.customersummary", {
            customer_id: customer.id,
            dealer_id: customer.dealer_id,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectVideoDevice(videoDevice) {
      UserService.setSelectedDealer(
        new DealerService({
          dealer_id: videoDevice.control_system.customer.dealer_id,
        })
      )
        .then(function () {
          if (
            videoDevice?.control_system?.panels?.[0].hardware_model === "X001"
          ) {
            $state.go("app.sites.edit", {
              customer_id: videoDevice.control_system.customer_id,
              site_id: videoDevice.control_system.site_id,
            });
          } else {
            $state.go("app.control_system", {
              customer_id: videoDevice.control_system.customer_id,
              control_system_id: videoDevice.control_system.id,
            });
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectThirdPartyVideoDevice(videoDevice) {
      UserService.setSelectedDealer(
        new DealerService({
          dealer_id: videoDevice.control_system.customer.dealer_id,
        })
      )
        .then(function () {
          $state.go("app.control_system", {
            customer_id: videoDevice.control_system.customer_id,
            control_system_id: videoDevice.control_system.id,
          });
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectXVDevice(xv) {
      var customer = new Customer();
      customer
        .get(xv.customer_id)
        .then(
          function () {
            UserService.setSelectedDealer(
              new DealerService({ dealer_id: customer.dealer_id })
            )
              .then(function () {
                $state.go("app.edit-var", {
                  customer_id: customer.id,
                  control_system_id: xv.control_system_id,
                  var_hub_id: xv.hub_id,
                });
              })
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            console.error(
              "Search main - selectXVDevice() error: " + angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to select dealer",
              json: error,
            });
          },
          function (info) {
            console.info(
              "Search main - selectXVDevice() info: " + angular.toJson(info)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectXVControlSystem(xv) {
      var customer = new Customer();
      customer
        .get(xv.customer_id)
        .then(
          function () {
            UserService.setSelectedDealer(
              new DealerService({ dealer_id: customer.dealer_id })
            )
              .then(function () {
                $state.go("app.control_system", {
                  customer_id: customer.id,
                  control_system_id: xv.control_system_id,
                });
              })
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            console.error(
              "Search main - selectXVControlSystem() error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to select dealer",
              json: error,
            });
          },
          function (info) {
            console.info(
              "Search main - selectXVControlSystem() info: " +
                angular.toJson(info)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectControlSystem(system) {
      var customer = new Customer();
      customer
        .get(system.customer_id)
        .then(
          function () {
            UserService.setSelectedDealer(
              new DealerService({ dealer_id: customer.dealer_id })
            )
              .then(function () {
                if (system.panels[0].hardware_model === "X001") {
                  $state.go("app.sites.edit", {
                    customer_id: customer.id,
                    site_id: system.site_id,
                  });
                } else {
                  $state.go("app.control_system", {
                    customer_id: customer.id,
                    control_system_id: system.id,
                  });
                }
              })
              .catch(function (error) {
                console.error(error);
              });
          },
          function (error) {
            console.error(
              "Search main - selectControlSystem() error: " +
                angular.toJson(error)
            );
            $rootScope.alerts.push({
              type: "error",
              text: "Unable to select dealer",
              json: error,
            });
          },
          function (info) {
            console.info(
              "Search main - selectControlSystem() info: " +
                angular.toJson(info)
            );
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function selectUser(user) {
      var dealerService = {};
      switch (user.accessible_type.toLowerCase()) {
        case "supervisor":
        case "dealer":
          dealerService = new DealerService({ dealer_id: user.accessible_id });
          break;
        case "customer":
        default:
          dealerService = new DealerService({
            customer_id: user.accessible_id,
          });
          break;
      }
      UserService.setSelectedDealer(dealerService)
        .then(function () {
          switch (user.accessible_type.toLowerCase()) {
            case "supervisor":
            case "dealer":
              $state.go("app.dealer.personnel_edit", {
                dealer_id: user.accessible_id,
                user_id: user.id,
              });
              break;
            case "customer":
            default:
              $state.go("app.user.viewuser", {
                customer_id: user.accessible_id,
                user_id: user.id,
              });
              break;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    //Separate Init Functions for searches
    function customerSearchInit(param) {
      $scope.sortType = "customer_name";
      $scope.sortReverse = false;
      var dealerId =
        UserService.isSupervisorAccessible() &&
        angular.isUndefined(UserService.selectedDealer)
          ? null
          : UserService.dealer_id;
      SearchDataService.customerSearch(dealerId, param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function videoDevicesSearchInit(param) {
      var dealerId =
        UserService.isSupervisorAccessible() &&
        angular.isUndefined(UserService.selectedDealer)
          ? null
          : UserService.dealer_id;
      SearchDataService.videoDeviceSearch(dealerId, param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function thirdPartyVideoDevicesSearchInit(param) {
      var dealerId =
        UserService.isSupervisorAccessible() &&
        angular.isUndefined(UserService.selectedDealer)
          ? null
          : UserService.dealer_id;
      SearchDataService.thirdPartyVideoDeviceSearch(dealerId, param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function dealerSearchInit(param) {
      SearchDataService.dealersSearch(param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function alarmVisionSearchInit(param) {
      SearchDataService.xvSearch(param)
        .then(
          function (data) {
            var newData = [data];
            $scope.results = newData;
            $scope.resultsFound = newData.length >= 1;
            resetPage();
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          },
          function (error) {
            $scope.error = error;
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function usersSearchInit(param) {
      var dealerId =
        UserService.isSupervisorAccessible() &&
        angular.isUndefined(UserService.selectedDealer)
          ? null
          : UserService.dealer_id;
      SearchDataService.usersSearch(dealerId, param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.results.map(function (user) {
              if (angular.isUndefined(user.accessible_id)) {
                user.accessible_id = user.customer_id;
                user.accessible_type = "customer";
              }
            });
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function systemsSearchInit(param) {
      var dealerId =
        UserService.isSupervisorAccessible() &&
        angular.isUndefined(UserService.selectedDealer)
          ? null
          : UserService.dealer_id;
      SearchDataService.systemsSearch(dealerId, param)
        .then(
          function (data) {
            $scope.results = data;
            $scope.resultsFound = isThereData(data);
            resetPage();
          },
          function (error) {
            $scope.error = error;
          },
          function (notification) {
            $scope.results = notification;
            $scope.resultsFound = isThereData(notification);
          }
        )
        .catch(function (error) {
          console.error(error);
        });
    }

    function isThereData(dataArray) {
      return dataArray.length > 0;
    }

    function addDealer() {
      if (angular.isUndefined(UserService.selectedDealer)) {
        $state.go("app.dealer.settings-new", { dealer_id: "new" });
      } else {
        UserService.unsetSelectedDealer(
          new DealerService({ dealer_id: UserService.user.accessible_id })
        )
          .then(
            function () {
              $state.go("app.dealer.settings-new", { dealer_id: "new" });
            },
            function (error) {
              $rootScope.alerts.push({
                type: "error",
                text: "Unable to deselect dealer",
                json: error,
              });
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
    }

    var cachedCriteria = undefined;
    function checkForCriteriaChange() {
      if ($scope.search.criteria !== cachedCriteria) {
        resetResults();
      }
      cachedCriteria = $scope.search.criteria;
    }

    doSearch();
  },
]);
