import { SecureComEnv } from "common/utils/universal/types";
import { getDealerAdminEnv } from "utils/env";
import { App } from "./app-module";
import { getDAUrls } from "./utils/securecom-urls";

/**
 *  The fall through for these should all be production
 *
 */

const getAuthAccessTypeKey = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return "c93385ef-5620-48dc-9f22-280d01a07195";
    default:
      return "709fcfbb-685f-49c3-a03f-82bdaff74297";
  }
};

const getGoogleAnalyticsId = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return "UA-88262062-1";
    default:
      return "UA-88262062-3";
  }
};

/**
 * Sets the debug level of the angular app
 *
 * * 0 = OFF
 * * 1 = DEBUG
 */
const getDebugLevel = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return 1;
    default:
      return 0;
  }
};

const getVVDebugDealers = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return new Set([
        1, // DMP
        2, // Atlas Security
        495, // Protection One (ADT)
        634, // DMP - Justin Braden
        643, // Navco Security Systems
        707, // INTERFACE SECURITY SYSTEMS LLC
        741, // DMP - Web Services, Mobile
        1029, // CBE/OWL
        1879, // Tacoz Tuesdayz (Embedded Testing)
        1892, // DMP - Tony Garcia (Embedded Testing)
        1903, // DMP - Ronnie Melton (Embedded Testing)
        1904, // DMP - Taylor Wylie (Embedded Testing)
      ]);
    default:
      //production
      return new Set([
        524, // SP
      ]);
  }
};

const getLevelUpDealers = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return [741, 241, 1239, 1118, 1321, 388, 505, 1918];
    default:
      //production
      return [741, 241, 1239, 1118, 1321, 388, 505, 1918];
  }
};

const getAuthorizedListSendAllUsers = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return new Set([
        "dgeorge@dmp.com", // Dennis George
        "dkugler@dmp.com", // Duane Kugler
        "jcaldwell@dmp.com", // Jesse Caldwell
        "jwylie@dmp.com", //Jordan Wylie
        "jwylie+7@dmp.com", //Jordan Wylie
        "kmweigand1970+all-powerful@gmail.com", // Ken Weigand
        "ndisclafani@dmp.com", // Nick DiSclafani
        "sbowen@dmp.com", // Steven Bowen
        "slewis@dmp.com", // Scott Lewis
        "jbraden@dmp.com", //Justin Braden
      ]);
    case "production":
    default:
      return new Set([
        "dgeorge@dmp.com", // Dennis George
        "dkugler@dmp.com", // Duane Kugler
        "jcaldwell@dmp.com", // Jesse Caldwell
        "ndisclafani@dmp.com", // Nick DiSclafani
        "sbowen@dmp.com", // Steven Bowen
        "slewis@dmp.com", // Scott Lewis
        "jbraden@dmp.com", //Justin Braden
        "dparnell@dmp.com", //Dolan Parnell
      ]);
  }
};

const getCustomBrandingUsers = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return new Set([
        "kmweigand1970+all-powerful@gmail.com", // Ken Weigand
        "jbrumfield@dmp.com", //Josh Brumfield
        "jposey@dmp.com", //John Posey
        "tgarcia@dmp.com", //Tony Garcia
        "rmelton@dmp.com", //Ronnie Melton
        "bhillenburg@dmp.com", //Barrett Hillenburg
        "amcghee@dmp.com", //Arron McGhee
        "jwylie+7@dmp.com", //Jordan Wylie
        "david.pok+techsupport@gmail.com", //David Pok
        "wf.pascoe@gmail.com", // William Pascoe(Eight Development Team)
        "dan.zhou@eightdevelopment.com", // Dan Zhou(Eight Development Team)
        "thetony25+tech@gmail.com", //Tony Garcia
        "rmelton+st@dmp.com", //Ronnie Melton
        "jbraden@dmp.com", //Justin Braden
        "slewis@dmp.com", //Scott Lewis
      ]);
    case "production":
    default:
      return new Set([
        "jbrumfield@dmp.com", //Josh Brumfield
        "jposey@dmp.com", //John Posey
        "bhillenburg@dmp.com", //Barrett Hillenburg
        "amcghee@dmp.com", //Arron McGhee
        "sbowen@dmp.com", //Steven Bowen
        "jcaldwell@dmp.com", //Jesse Caldwell
        "dparnell@dmp.com", //Dolan Parnell
        "dgeorge@dmp.com", //Dennis George
        "jbraden@dmp.com", //Justin Braden
        "slewis@dmp.com", //Scott Lewis
      ]);
  }
};

const getTechSupportLoginAsCustomer = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
      return new Set(["david.pok+techsupport@gmail.com", "jwylie@dmp.com"]);
    case "dev2":
    case "production":
    default:
      return new Set([
        "ndisclafani@dmp.com",
        "dkugler@dmp.com",
        "jflessing@dmp.com",
        "kshelby@dmp.com",
        "kbrown@dmp.com",
        "dpok@dmp.com",
      ]);
  }
};

const getTagDealers = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return new Set([
        1, // DMP
        2, // Atlas Security
        495, // Protection One (ADT)
        634, // DMP - Justin Braden
        643, // Navco Security Systems
        707, // INTERFACE SECURITY SYSTEMS LLC
        741, // DMP - Web Services, Mobile
        1029, // CBE/OWL
        1879, // Tacoz Tuesdayz (Embedded Testing)
        1892, // DMP - Tony Garcia (Embedded Testing)
        1903, // DMP - Ronnie Melton (Embedded Testing)
        1904, // DMP - Taylor Wylie (Embedded Testing)
      ]);
    case "production":
    default:
      return new Set([
        1, // DMP
        250, // DMP Demo Room
        495, // Protection One (ADT)
        634, // DMP - Justin Braden
        643, // NAVCO
        707, // Interface Security Systems, LLC
        1029, // CBE, INC
        1206, // DMP - Tony Garcia (Embedded Testing)
        1495, // DMP - Ronnie Melton (Embedded Testing)
        1502, // DMP - Taylor Wylie (Embedded Testing)
        1512, // DMP - Jodan Wylie (Embedded Testing)
        1875, // ADT Demo
      ]);
  }
};

const getOrbiPayClientId = (env: SecureComEnv) => {
  switch (env) {
    case "dev1":
    case "scdev":
    case "dev2":
      return "8093860472";
    default:
      return "2592338155";
  }
};

export const dangerouslyAddPropertiesToApp = ({
  env,
  appDate,
  appVersion,
  sha,
  stagingBranch,
}: {
  env: SecureComEnv;
  appDate: string;
  appVersion: string;
  sha: string;
  stagingBranch?: string;
}) => {
  App.constant("PROPS", {
    ...getDAUrls({ env, stagingPrefixForClients: stagingBranch }),
    secureComEnv: env,
    environment: getDealerAdminEnv(env),
    LEVELUP_DEALERS: getLevelUpDealers(env),
    CUSTOM_BRANDING_USERS: getCustomBrandingUsers(env),
    AUTHORIZED_LIST_SEND_ALL_USERS: getAuthorizedListSendAllUsers(env),
    TAG_DEALERS: getTagDealers(env),
    authAccessTypeKey: getAuthAccessTypeKey(env),
    googleAnalyticsId: getGoogleAnalyticsId(env),
    debug: getDebugLevel(env),
    orbiPayClientId: getOrbiPayClientId(env),
    VV_DEBUG_DEALERS: getVVDebugDealers(env),
    TECH_SUPPORT_TEMP_LOGIN_AS_CUSTOMER: getTechSupportLoginAsCustomer(env),
    appDate,
    appVersion,

    // Defaulting Tech App Specific Properties
    mobileGoogleAnalyticsId: "UA-********-0",
    appMobileVersion: "0.0.0",
    appMobileDate: appDate,
    appMobileCommitId: sha,
    techappEmailRecipients: ["techapp@dmp.com"],

    webCommitId: "",
    simsLogin: "api",
    simsPassword: "MsovCQ5IHN0CmBArye1z",
    alertSuccessTimeout: 4000, // How long (in ms) to leave a Success notification visible.
    alertErrorTimeout: 60000, // How long (in ms) to leave an Error notification visible.
    alertSuccess: "success",
    alertError: "error",
    alertInfo: "info",
    alertCritical: "critical",
    pollMaxTimes: 300, // Total number of times the JobService will check status.
    pollWaitTime: 1000, // Time between JobService status requests.
    signalThresholdVerizon: -84, // values below this are considered below the threshold (so -85 is below)
    signalThresholdATT: -84, // values below this are considered below the threshold,
    updateWatcherInterval: 5000, // value in ms to wait between checking for the status of remote updates
    multiPanelWatcherInterval: 5000, // value in ms to wait between checking for the status of multi-panel jobs
    initialConnectionWatcherInterval: 1000, // value in ms to check the status of an initial connection job
    importWatcherInterval: 1000,
    mapDataExpTimeout: 14400000, // How long (in ms) control system map data will be cached before it is requested again.
    ipapiKey: "VKzE8XADHaBnSbuF6PkGrl4cdF1BuAkbzkHiuuNEr8f99gkJT0",
  });
};
