/**=========================================================
 * Module: constants.js
 * Define constants to inject across the application
 =========================================================*/

(() => {
  /**
   * The production servers of Dealer Admin include an "x-node" header.
   * This header is used to troubleshoot network and infrastructure problems
   * that occur off hours. Often the only data received to troubleshoot
   * these issues is a screenshot from a dealer. The host node being visually
   * in the footer, provides a starting point for troubleshooting these issues.
   */
  const nodeIsMissingIdentifier = "0";

  fetch(document.location)
    .then((response) => {
      const node = response.headers.get("x-node");

      if (typeof node === "string") {
        App.constant("HOST_NODE_ID", Number(node.slice(-2)).toString());
      } else {
        App.constant("HOST_NODE_ID", nodeIsMissingIdentifier);
      }
    })
    .catch((error) => {
      App.constant("HOST_NODE_ID", nodeIsMissingIdentifier);
    });
})();

App.constant("BETA", {
  reportList: [
    1, //DMP
    741, //Web Services
    2, //Atlas
    49, //Nightwatch Security & Telephone Customers
    191, //Rueschoff
    261, //NECO Security
    320, //BSASI
    439, //DMP - Lew Kramer
  ],
})
  .constant("KEYPAD_BANNER_SEND", {
    DEALERS: [381, 741, 241],
  })
  .constant("SPECIAL_DEALERS", {
    BayAlarm: 381,
  })
  .constant("NEWS_ITEMS", {
    NewsItemsUsers: [
      "updates@dmp.com",
      "aprifti@dmp.com", // Alex Prifti
      "bappleby@dmp.com", // Brent Appleby
      "bhillenburg@dmp.com", // Barrett Hillenburg
      "cinfante@dmp.com", // Candida Infante
      "dgeorge@dmp.com", // Dennis George
      "jcaldwell@dmp.com", //Jesse Caldwell
      "jbraden@dmp.com", // Justin Braden
      "jbritton@dmp.com", // Jeff Britton
      "sarogers@dmp.com", // Sarah Rogers
      "sbowen@dmp.com", // Steven Bowen
      "dparnell@dmp.com", //Dolan Parnell
      "slewis@dmp.com", // Scott Lewis
      "thetony25+news@gmail.com", //Tony Garcia (for testing)
      "tgarcia@dmp.com", // Tony Garcia (For prod_eu testing)
      "rteller@dmp.com", // Rabekah Teller
      "lshaulis@dmp.com", //Lydia Shaulis
      "mdivito@dmp.com", //Meg DiVito
      "kworley+stech@dmp.com", // Keaton Worley (for testing prod_eu)
    ],
    NO_END_DATE_EPOCH: 32503744800000,
  })

  .constant("NO_NEWS", {
    NoNewsUsers: [
      "qatesting+admin@dmp.com",
      "qatesting+customtech@dmp.com",
      "qatesting+operator@dmp.com",
      "qatesting+tech@dmp.com",
    ],
  })

  .constant("ACCOUNTING_REPORTS", {
    AccountingReportsUsers: [
      "cpinkley@dmp.com",
      "sdunaway@dmp.com",
      "msanders@dmp.com",
      "updates@dmp.com",
      "ctummons@dmp.com",
      "cstange@dmp.com",
      "clawrence@dmp.com",
      "jbritton@dmp.com",
    ],
  })

  .constant("PANEL_CONCEPTS", {
    zone_statuses: { api_name: "zone_statuses", data_name: "Zone Statuses" },
    communication: { api_name: "communication", data_name: "Communication" },
    communication_paths: {
      api_name: "communication_paths",
      data_name: "Communication Paths",
      single_name: "Communication Path",
    },
    remote_options: { api_name: "remote_options", data_name: "Remote Options" },
    system_options: { api_name: "system_options", data_name: "System Options" },
    system_reports: { api_name: "system_reports", data_name: "System Reports" },
    output_options: { api_name: "output_options", data_name: "Output Options" },
    output_groups: {
      api_name: "output_groups",
      data_name: "Output Groups",
      single_name: "Output Group",
    },
    network_options: {
      api_name: "network_options",
      data_name: "Network Options",
    },
    messaging_setup: {
      api_name: "messaging_setup",
      data_name: "Messaging Setup",
    },
    bell_options: { api_name: "bell_options", data_name: "Bell Options" },
    favorites: {
      api_name: "favorites",
      data_name: "Favorites",
      single_name: "Favorite",
    },
    groups: {
      //Not a real concept but needed for the XT75 series panels which fake profiles as groups
      api_name: "groups",
      data_name: "Groups",
      single_name: "Group",
    },
    output_informations: {
      api_name: "output_informations",
      data_name: "Output Information",
      single_name: "Output",
    },
    device_informations: {
      api_name: "device_informations",
      data_name: "Device Setup",
      single_name: "Device",
    },
    area_informations: {
      api_name: "area_informations",
      data_name: "Area Information",
      single_name: "Area",
    },
    zone_informations: {
      api_name: "zone_informations",
      data_name: "Zone Information",
      single_name: "Zone",
    },
    time_schedules: {
      api_name: "time_schedules",
      data_name: "Time Schedules",
      single_name: "Schedule",
    },
    system_area_information: {
      api_name: "system_area_information",
      data_name: "System Area Information",
    },
    schedules: {
      api_name: "schedules",
      data_name: "Arming Schedules",
      single_name: "Arming Schedule",
    },
    favorite_schedules: {
      api_name: "favorite_schedules",
      data_name: "Favorite Schedules",
      single_name: "Favorite Schedule",
    },
    output_schedules: {
      api_name: "output_schedules",
      data_name: "Output Schedules",
      single_name: "Output Schedule",
    },
    user_codes: {
      api_name: "user_codes",
      data_name: "User Codes",
      single_name: "User Code",
    },
    profiles: {
      api_name: "profiles",
      data_name: "Profiles",
      single_name: "Profile",
    },
    keyfobs: {
      api_name: "keyfobs",
      data_name: "Key Fobs",
      single_name: "Key Fob",
    },
    capabilities: {
      api_name: "capabilities",
      data_name: "Capabilities",
      single_name: "Capability",
    },
    feature_keys: { api_name: "feature_keys", data_name: "Feature Keys" },
    status_list: { api_name: "status_list", data_name: "Status List" },
    lockout_code: { api_name: "lockout_code", data_name: "Lockout Code" },
    zwave_setups: {
      api_name: "zwave_setups",
      data_name: "Z-Wave Devices",
      single_name: "Z-Wave Device",
    },
    card_formats: {
      api_name: "card_formats",
      data_name: "Card Formats",
      single_name: "Card Format",
    },
    menu_display: { api_name: "menu_display", data_name: "Menu Display" },
    holiday_dates: {
      api_name: "holiday_dates",
      data_name: "Holiday Dates",
      single_name: "Holiday Date",
    },
    security_grade: {
      api_name: "security_grade",
      data_name: "Security Grade",
    },
  })
  .constant("PANEL_DEF_CONSTANTS", {
    DMP_PANEL_RULES: "DMP_Panel_Rules",
    TARGET: "Target",
    REFERENCE_TYPE: "Reference_Type",
    OPERATOR: "Operator",
    REFERENCE_VALUES_MAP: "Reference_Values_Map",
    REFERENCE_NAME: "Reference_Name",
    RULE_TYPE: "Rule_Type",
    VIRTUALLINK_DATA: "VirtualLink_data",
  })
  .constant("PANEL_COMM_TYPE", {
    WIFI: "1",
    CONTACT_ID: "5",
    CELL: "6",
    NETWORK: "7",
    DIGITAL_DIALER: "8",
  })
  .constant("XR_PANEL_COMM_TYPE", {
    WIFI: "W",
    NETWORK: "N",
    CELL: "L",
  })
  .constant("PANEL_SHARED_CONCEPTS", [
    "keyfobs",
    "output_informations",
    "zone_informations",
  ])
  .constant("DAY_NOT_ENCODED_SCHEDULE_CONCEPTS", [
    // (XT30 family) schedule concepts for which the day is not encoded with the time
    "favorite_schedules",
    "output_schedules",
  ])

  .constant("PANEL_NULL_EXCLUSION_LIST", {
    user_codes: ["snd_to_lks", "stl_status", "user_id"],
    output_schedules: ["_twilight_"],
    favorite_schedules: ["_twilight_"],
    time_schedules: ["_twilight_"],
  })

  .constant("WALK_TEST_TYPE", {
    STANDARD: "S",
    WIRELESS: "W",
    PIR: "P",
  })
  .constant("COMM_TEST_TYPES", {
    All: "0",
    Network: "N",
    Cellular: "C",
  })
  .constant("PANEL_TEST_TYPES", {
    COMM_TEST: "communication_test",
    SENSOR_TEST: "sensor_test",
    WIRELESS_TEST: "wireless_test",
    ZWAVE_DIAGNOSTICS_TEST: "zwave_test",
    ZWAVE_OPTIMIZATION: "zwave_optimize",
    PIR_WALK_TEST: "walk_test",
  })
  .constant("PANEL_TEST_STATUSES", {
    NEW: "NEW",
    RUNNING: "RUNNING",
    CANCELLED: "CANCELLED",
    STOPPED: "STOPPED",
  })
  .constant("ERROR_CODES", {
    INVALID_REMOTE_KEY: "2",
    INVALID_ACCOUNT_NUMBER: "3",
    CONNECTION_TIMED_OUT: "7",
    PANEL_ERROR: "-1",
  })
  .constant("REMOTE_UPDATE_VERSIONS", {
    XR_MIN_VERSION: "000",
    XR_MIN_DATECODE: "01-01-00",
    XT_MIN_VERSION: "123",
    XT_MIN_DATECODE: "06-30-15",
    XT_MIN_SERIAL: "00044E5A",
    XT_INT_VERSION: "600",
  })
  .constant("PANEL_TEST_VERSIONS", {
    XT_MIN_VERSION: "125",
    XR_MIN_VERSION: "172",
    XF6_MIN_VERSION: "101",
  })
  .constant("SCHEDULES", {
    XR150_MAX_SCHEDULES: "99",
    XR350_MAX_SCHEDULES: "99",
    XR550_MAX_SCHEDULES: "99",
  })
  .constant("APP_COLORS", {
    primary: "#5d9cec",
    success: "#27c24c",
    info: "#23b7e5",
    warning: "#ff902b",
    danger: "#f05050",
    inverse: "#131e26",
    green: "#37bc9b",
    pink: "#f532e5",
    purple: "#7266ba",
    dark: "#3a3f51",
    yellow: "#fad732",
    "gray-darker": "#232735",
    "gray-dark": "#3a3f51",
    gray: "#dde6e9",
    "gray-light": "#e4eaec",
    "gray-lighter": "#edf1f2",
    chartThreshold: "#ED595A",
    chartNormalColor: "#92D8EC",
    chartThresholdSim2: "#ED595A",
    chartNormalColorSim2: "#28708F",
  })
  .constant("APP_MEDIAQUERY", {
    desktopLG: 1200,
    desktop: 992,
    tabletmax: 800,
    tablet: 768,
    mobile: 480,
  })
  .constant("VIDEO", {
    free_video_channels: 8,
  })
  .constant("VIDEO_MOTION_DETECTION", {
    camera_day_sensitivity_level: 80,
    camera_night_sensitivity_level: 80,
    nvr_day_sensitivity_level: 4,
    nvr_night_sensitivity_level: 4,
    day_object_size: 4,
    night_object_size: 4,
    day_object_size_hikvision_2020: 0,
    night_object_size_hikvision_2020: 0,
  })
  .constant("VIDEO_DEVICE_MODELS", {
    // New Hikvision cameras (May 2020 release)
    V052D: "V-4052D",
    V4022C: "V-4022C",
    DS2CD2443G0IW: "DS-2CD2443G0-IW",
    DS2CD2123G0I: "DS-2CD2123G0-I",
    DS2CD2123G0IS: "DS-2CD2123G0-IS",
    DS2CD2523G0IWS: "DS-2CD2523G0-IWS",
  })
  .constant("DAYS_OF_WEEK", {
    Sunday: { abbr: "Sun", number: 1 },
    Monday: { abbr: "Mon", number: 2 },
    Tuesday: { abbr: "Tue", number: 3 },
    Wednesday: { abbr: "Wed", number: 4 },
    Thursday: { abbr: "Thu", number: 5 },
    Friday: { abbr: "Fri", number: 6 },
    Saturday: { abbr: "Sat", number: 7 },
  })
  .constant("COUNTRY_CODES", [
    { country: "United States", code: "US", language: "English" },
    { country: "Angola", code: "AO", language: "Portuguese" },
    { country: "Argentina", code: "AR", language: "Spanish" },
    { country: "Austria", code: "AT", language: "German" },
    { country: "Bahamas", code: "BS", language: "English" },
    { country: "Barbados", code: "BB", language: "English" },
    { country: "Belgium", code: "BE", language: "Dutch" },
    { country: "Bolivia", code: "BO", language: "Spanish" },
    { country: "Canada", code: "CA", language: "French" },
    { country: "Cayman Islands", code: "KY", language: "English" },
    { country: "Chile", code: "CL", language: "Spanish" },
    { country: "Colombia", code: "CO", language: "Spanish" },
    { country: "Costa Rica", code: "CR", language: "Spanish" },
    { country: "Croatia", code: "HR", language: "Croatian" },
    { country: "Czech Republic", code: "CZ", language: "Slovak" },
    {
      country: "United Kingdom of Great Britain and Northern Ireland",
      code: "GB",
      language: "English",
    },
    { country: "France", code: "FR", language: "French" },
    { country: "Germany", code: "DE", language: "German" },
    { country: "Greece", code: "GR", language: "Greek" },
    { country: "Hong Kong", code: "HK", language: "Cantonese" },
    { country: "Hungary", code: "HU", language: "Hungarian" },
    { country: "Ireland", code: "IE", language: "English" },
    { country: "Italy", code: "IT", language: "Italian" },
    { country: "Jamaica", code: "JM", language: "English" },
    { country: "Mexico", code: "MX", language: "Spanish" },
    { country: "Mozaambique", code: "MZ", language: "Portuguese" },
    { country: "Namibia", code: "NA", language: "Afrikaans" },
    { country: "Netherlands", code: "NL", language: "Dutch" },
    { country: "Panama", code: "PA", language: "Spanish" },
    { country: "Peru", code: "PE", language: "Spanish" },
    { country: "Poland", code: "PL", language: "Polish" },
    { country: "Portugal", code: "PT", language: "Portuguese" },
    { country: "Repubica Dominicana", code: "DO", language: "Spanish" },
    { country: "Slovenia", code: "SI", language: "Slovenian" },
    { country: "South Africa", code: "ZA", language: "Afrikaans" },
    { country: "Spain", code: "ES", language: "Spanish" },
    { country: "Suriname", code: "SR", language: "Dutch" },
    { country: "Switzerland", code: "CH", language: "German" },
    { country: "Trinidad Y Tobago", code: "TT", language: "English" },
    { country: "Venenzuela", code: "VE", language: "Spanish" },
    { country: "Zimbabwe", code: "ZW", language: "English" },
  ])
  .constant("STATE_CODES", {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District of Columbia",
    FM: "Federated States of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  })
  .constant("DEALER_API_DEFAULTS", {
    XT_DEFAULTS: "xt_defaults",
    XR_DEFAULTS: "programmingTemplates",
    VK_DEFAULTS: "vk_defaults",
    APP_USER_DEFAULTS: "app_user_defaults",
    SERVICES_DEFAULTS: "services_defaults",
  })

  .constant("PROGRAMMING_GUIDE", {
    uri: "https://www.dmp.com/assets/",
    XTL: "LT-1108.pdf",
    XTLC: "LT-1108.pdf",
    XTLN: "LT-1221.pdf",
    "XTLN-WIFI": "LT-1221.pdf",
    CellComSL: "LT-1817.pdf",
    CellComSLZ: "LT-1817.pdf",
    CellComEX: "LT-2663.pdf",
    iComSL: "LT-1413.pdf",
    DualCom: "LT-1859.pdf",
    XT30: "LT-0981.pdf",
    XT50: "LT-0981.pdf",
    XTLP: "LT-1434.pdf",
    XTLtouch: "LT-1789.pdf",
    XR150: "LT-1232.pdf",
    XR350: "LT-1232.pdf",
    XR550: "LT-1232.pdf",
    XF6_500: "LT-2777.pdf",
    XF6_100: "LT-2777.pdf",
  })
  .constant("INSTALL_GUIDE", {
    uri: "https://www.dmp.com/assets/",
    XTL: "LT-1105.pdf",
    XTLC: "LT-1105.pdf",
    XTLN: "LT-1220.pdf",
    "XTLN-WIFI": "LT-1220.pdf",
    CellComSL: "LT-1817.pdf",
    CellComSLZ: "LT-1817.pdf",
    CellComEX: "LT-2663.pdf",
    iComSL: "LT-1413.pdf",
    DualCom: "LT-1859.pdf",
    XT30: "LT-0980.pdf",
    XT50: "LT-0980.pdf",
    XTLP: "LT-1434.pdf",
    XTLtouch: "LT-1788.pdf",
    XR150: "LT-1233.pdf",
    XR350: "LT-1233.pdf",
    XR550: "LT-1233.pdf",
    XF6_500: "LT-2777.pdf",
    XF6_100: "LT-2777.pdf",
  })
  .constant("TIME_ZONES_NVR2", [
    "Eastern Time (US & Canada)",
    "Central Time (US & Canada)",
    "Mountain Time (US & Canada)",
    "Pacific Time (US & Canada)",
    "Alaska",
    "Hawaii",
  ])
  .constant("TIME_ZONES_NAMES_MAP", {
    "Eastern Time (US & Canada)": "Eastern Standard Time",
    "Central Time (US & Canada)": "Central Standard Time",
    "Mountain Time (US & Canada)": "Mountain Standard Time",
    "Pacific Time (US & Canada)": "Pacific Standard Time",
    Alaska: "Alaskan Standard Time",
    Hawaii: "Hawaiian Standard Time",
  })
  .constant("TIME_ZONES_NAMES_WINDOWS_SERVER", [
    "Eastern Standard Time",
    "Central Standard Time",
    "Mountain Standard Time",
    "US Mountain Standard Time",
    "Pacific Standard Time",
    "Alaskan Standard Time",
    "Hawaiian Standard Time",
  ])
  .constant("TIME_ZONES", [
    "Eastern Time (US & Canada)",
    "Central Time (US & Canada)",
    "Mountain Time (US & Canada)",
    "Pacific Time (US & Canada)",
    "Alaska",
    "Arizona",
    "Hawaii",
    "Indiana (East)",
    "Abu Dhabi",
    "Adelaide",
    "Almaty",
    "American Samoa",
    "Amsterdam",
    "Astana",
    "Athens",
    "Atlantic Time (Canada)",
    "Auckland",
    "Azores",
    "Baghdad",
    "Baku",
    "Bangkok",
    "Beijing",
    "Belgrade",
    "Berlin",
    "Bern",
    "Bogota",
    "Brasilia",
    "Bratislava",
    "Brisbane",
    "Brussels",
    "Bucharest",
    "Budapest",
    "Buenos Aires",
    "Cairo",
    "Canberra",
    "Cape Verde Is.",
    "Caracas",
    "Casablanca",
    "Central America",
    "Chatham Is.",
    "Chennai",
    "Chihuahua",
    "Chongqing",
    "Copenhagen",
    "Darwin",
    "Dhaka",
    "Dublin",
    "Edinburgh",
    "Ekaterinburg",
    "Fiji",
    "Georgetown",
    "Greenland",
    "Guadalajara",
    "Guam",
    "Hanoi",
    "Harare",
    "Helsinki",
    "Hobart",
    "Hong Kong",
    "Irkutsk",
    "International Date Line West",
    "Islamabad",
    "Istanbul",
    "Jakarta",
    "Jerusalem",
    "Kabul",
    "Kaliningrad",
    "Kamchatka",
    "Karachi",
    "Kathmandu",
    "Kolkata",
    "Krasnoyarsk",
    "Kuala Lumpur",
    "Kuwait",
    "Kyiv",
    "La Paz",
    "Lima",
    "Lisbon",
    "Ljubljana",
    "London",
    "Madrid",
    "Magadan",
    "Marshall Is.",
    "Mazatlan",
    "Melbourne",
    "Mexico City",
    "Mid-Atlantic",
    "Midway Island",
    "Minsk",
    "Monrovia",
    "Monterrey",
    "Montevideo",
    "Moscow",
    "Mumbai",
    "Muscat",
    "Nairobi",
    "New Caledonia",
    "New Delhi",
    "Newfoundland",
    "Novosibirsk",
    "Nuku'alofa",
    "Osaka",
    "Paris",
    "Perth",
    "Port Moresby",
    "Prague",
    "Pretoria",
    "Quito",
    "Rangoon",
    "Riga",
    "Riyadh",
    "Rome",
    "Samara",
    "Samoa",
    "Santiago",
    "Sapporo",
    "Sarajevo",
    "Saskatchewan",
    "Seoul",
    "Singapore",
    "Skopje",
    "Sofia",
    "Solomon Is.",
    "Srednekolymsk",
    "Sri Jayawardenepura",
    "St. Petersburg",
    "Stockholm",
    "Sydney",
    "Taipei",
    "Tallinn",
    "Tashkent",
    "Tbilisi",
    "Tehran",
    "Tijuana",
    "Tokelau Is.",
    "Tokyo",
    "UTC",
    "Ulaanbaatar",
    "Urumqi",
    "Vienna",
    "Vilnius",
    "Vladivostok",
    "Volgograd",
    "Warsaw",
    "Wellington",
    "West Central Africa",
    "Yakutsk",
    "Yerevan",
    "Zagreb",
  ])
  .constant("CAMECT_TIME_ZONES", [
    "US/Eastern",
    "US/Central",
    "US/Mountain",
    "US/Pacific",
    "US/Alaska",
    "US/Hawaii",
    "US/Aleutian",
    "US/Arizona",
    "US/East-Indiana",
    "US/Indiana-Starke",
    "US/Michigan",
    "US/Samoa",
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Asmera",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Timbuktu",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/ComodRivadavia",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Atka",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Buenos_Aires",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Catamarca",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Coral_Harbour",
    "America/Cordoba",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Ensenada",
    "America/Fort_Nelson",
    "America/Fort_Wayne",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Godthab",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indianapolis",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Jujuy",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Knox_IN",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Louisville",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Mendoza",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montreal",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port_of_Spain",
    "America/Port-au-Prince",
    "America/Porto_Acre",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Rosario",
    "America/Santa_Isabel",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Shiprock",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Virgin",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/South_Pole",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Ashkhabad",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Calcutta",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Chongqing",
    "Asia/Chungking",
    "Asia/Colombo",
    "Asia/Dacca",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Gaza",
    "Asia/Harbin",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Istanbul",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kashgar",
    "Asia/Kathmandu",
    "Asia/Katmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macao",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qyzylorda",
    "Asia/Rangoon",
    "Asia/Riyadh",
    "Asia/Saigon",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Tel_Aviv",
    "Asia/Thimbu",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ujung_Pandang",
    "Asia/Ulaanbaatar",
    "Asia/Ulan_Bator",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faeroe",
    "Atlantic/Faroe",
    "Atlantic/Jan_Mayen",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/ACT",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Canberra",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/LHI",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/North",
    "Australia/NSW",
    "Australia/Perth",
    "Australia/Queensland",
    "Australia/South",
    "Australia/Sydney",
    "Australia/Tasmania",
    "Australia/Victoria",
    "Australia/West",
    "Australia/Yancowinna",
    "Brazil/Acre",
    "Brazil/DeNoronha",
    "Brazil/East",
    "Brazil/West",
    "Canada/Atlantic",
    "Canada/Central",
    "Canada/Eastern",
    "Canada/Mountain",
    "Canada/Newfoundland",
    "Canada/Pacific",
    "Canada/Saskatchewan",
    "Canada/Yukon",
    "CET",
    "Chile/Continental",
    "Chile/EasterIsland",
    "CST6CDT",
    "Cuba",
    "EET",
    "Egypt",
    "Eire",
    "EST",
    "EST5EDT",
    "Etc/GMT",
    "Etc/GMT+0",
    "Etc/GMT+1",
    "Etc/GMT+10",
    "Etc/GMT+11",
    "Etc/GMT+12",
    "Etc/GMT+2",
    "Etc/GMT+3",
    "Etc/GMT+4",
    "Etc/GMT+5",
    "Etc/GMT+6",
    "Etc/GMT+7",
    "Etc/GMT+8",
    "Etc/GMT+9",
    "Etc/GMT0",
    "Etc/GMT-0",
    "Etc/GMT-1",
    "Etc/GMT-10",
    "Etc/GMT-11",
    "Etc/GMT-12",
    "Etc/GMT-13",
    "Etc/GMT-14",
    "Etc/GMT-2",
    "Etc/GMT-3",
    "Etc/GMT-4",
    "Etc/GMT-5",
    "Etc/GMT-6",
    "Etc/GMT-7",
    "Etc/GMT-8",
    "Etc/GMT-9",
    "Etc/Greenwich",
    "Etc/UCT",
    "Etc/Universal",
    "Etc/UTC",
    "Etc/Zulu",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belfast",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Nicosia",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Tiraspol",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "GB",
    "GB-Eire",
    "GMT",
    "GMT+0",
    "GMT0",
    "GMT-0",
    "Greenwich",
    "Hongkong",
    "HST",
    "Iceland",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Iran",
    "Israel",
    "Jamaica",
    "Japan",
    "Kwajalein",
    "Libya",
    "MET",
    "Mexico/BajaNorte",
    "Mexico/BajaSur",
    "Mexico/General",
    "MST",
    "MST7MDT",
    "Navajo",
    "NZ",
    "NZ-CHAT",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Enderbury",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Johnston",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Ponape",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Samoa",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Truk",
    "Pacific/Wake",
    "Pacific/Wallis",
    "Pacific/Yap",
    "Poland",
    "Portugal",
    "PRC",
    "PST8PDT",
    "ROC",
    "ROK",
    "Singapore",
    "Turkey",
    "UCT",
    "Universal",
    "UTC",
    "WET",
    "W-SU",
    "Zulu",
  ])
  .constant("SENSOR_STATUS_READABLE", {
    M: "Missing",
    L: "Low Battery",
    X: "Bypassed",
    N: "Normal",
    S: "Short",
    O: "Open",
  })
  .constant("APP_REQUIRES", {
    scripts: {
      jquery: ["assets/libs/jquery/jquery.min.js"],
      icons: ["assets/libs/fontawesome/css/font-awesome.min.css"],
      modernizr: ["assets/libs/modernizr/modernizr.js"],
      fastclick: ["assets/libs/fastclick/fastclick.js"],
      filestyle: ["assets/libs/filestyle/bootstrap-filestyle.min.js"],
      csspiner: ["assets/libs/csspinner/csspinner.css"],
      animo: ["assets/libs/animo/animo.min.js"],
      sparklines: ["assets/libs/sparklines/jquery.sparkline.min.js"],
      slimscroll: ["assets/libs/slimscroll/jquery.slimscroll.min.js"],
      classyloader: ["assets/libs/classyloader/js/jquery.classyloader.min.js"],
      "vector-map": [
        "assets/libs/jvectormap/jquery-jvectormap-1.2.2.min.js",
        "assets/libs/jvectormap/maps/jquery-jvectormap-world-mill-en.js",
        "assets/libs/jvectormap/jquery-jvectormap-1.2.2.css",
      ],
      loadGoogleMapsJS: ["assets/libs/gmap/load-google-maps.js"],
      "google-map": ["assets/libs/gmap/jquery.gmap.min.js"],
      "flot-chart": ["assets/libs/flot/jquery.flot.modified.js"], //['assets/libs/flot/jquery.flot.min.js'],
      "flot-chart-plugins": [
        "assets/libs/flot/jquery.flot.orderBars.js",
        "assets/libs/flot/jquery.flot.tooltip.min.js",
        "assets/libs/flot/jquery.flot.resize.min.js",
        "assets/libs/flot/jquery.flot.pie.min.js",
        "assets/libs/flot/jquery.flot.time.min.js",
        "assets/libs/flot/jquery.flot.categories.min.js",
        "assets/libs/flot/jquery.flot.spline.min.js",
        "assets/libs/flot/jquery.flot.threshold.js",
      ],
      "jquery-ui": [
        "assets/libs/jqueryui/js/jquery-ui-1.10.4.custom.min.js",
        "assets/libs/touch-punch/jquery.ui.touch-punch.min.js",
      ],

      slider: [
        "assets/libs/slider/js/bootstrap-slider.js",
        "assets/libs/slider/css/slider.css",
      ],
      moment: ["assets/libs/moment/min/moment-with-langs.min.js"],
      fullcalendar: [
        "assets/libs/fullcalendar/fullcalendar.min.js",
        "assets/libs/fullcalendar/fullcalendar.css",
      ],
      codemirror: [
        "assets/libs/codemirror/lib/codemirror.js",
        "assets/libs/codemirror/lib/codemirror.css",
      ],
      "codemirror-plugins": [
        "assets/libs/codemirror/addon/mode/overlay.js",
        "assets/libs/codemirror/mode/markdown/markdown.js",
        "assets/libs/codemirror/mode/xml/xml.js",
        "assets/libs/codemirror/mode/gfm/gfm.js",
        "assets/libs/marked/marked.js",
      ],
      datetimepicker: [
        "assets/libs/datetimepicker/js/bootstrap-datetimepicker.min.js",
        "assets/libs/datetimepicker/css/bootstrap-datetimepicker.min.css",
      ],
      taginput: [
        "assets/libs/tagsinput/bootstrap-tagsinput.min.js",
        "assets/libs/tagsinput/bootstrap-tagsinput.css",
      ],
      inputmask: ["assets/libs/inputmask/jquery.inputmask.bundle.min.js"],
      bwizard: ["assets/libs/wizard/js/bwizard.min.js"],
      parsley: ["assets/libs/parsley/parsley.min.js"],
      flatdoc: ["assets/libs/flatdoc/flatdoc.js"],
      panels: ["assets/libs/angle/directives/panel-tools.js"],
      "checklist-model": ["assets/libs/checklist-model/checklist-model.js"],
      video: [],
      chosen: [
        "vendor/chosen_v1.2.0/chosen.jquery.min.js",
        "vendor/chosen_v1.2.0/chosen.min.css",
        "vendor/angular-chosen-localytics/chosen.js",
      ],
      "angular-cryptography": [
        "assets/libs/angular-cryptography/aes.js",
        "assets/libs/angular-cryptography/mdo-angular-cryptography.js",
      ],
    },

    modules: [
      {
        name: "toaster",
        files: [
          "assets/libs/toaster/toaster.js",
          "assets/libs/toaster/toaster.css",
        ],
      },
    ],
    name: "localytics.directives",
    files: [
      "vendor/chosen_v1.2.0/chosen.jquery.min.js",
      "vendor/chosen_v1.2.0/chosen.min.css",
      "vendor/angular-chosen-localytics/chosen.js",
    ],
  })
  .constant("PROGRAM_PANELS_OPEN", { max_panels: 2 })
  .constant("VALIDATION_PATTERNS", {
    IP_ADDRESS:
      "(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)",
    PORT: "((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])", //1-65535
    FAIL_TIME:
      "(0{0,3}|0{0,2}[3-9]|0{0,1}1[0-9]|0{0,1}[2-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)", //0,03-240
    CHECKIN_TIME:
      "(0{0,3}|0{0,2}[3-9]|0{0,1}1[0-9]|0{0,1}[2-9][0-9]|1[0-9][0-9]|2[0-3][0-9]|240)", //0,03-240
    TEST_DAYS: "([0]{0,1}[0-9]|[1-5][0-9]|6[0])", //0-60
    TRANS_DELAY: "([0]{0,2}|1[5-9]|[2-3][0-9]|4[0-5])", //0,15-45
    ENTRY_DELAY: "(0{0,1}[3-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)", //30-250
    EXIT_DELAY: "(0{0,1}4[5-9]|0{0,1}[5-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|250)", //45-250
    GMT_ZONE: "(0{0,1}[0-9]|1[0-9]|2[0-3])", //0-23
    HOUSE_CODE: "(0?[0-4]?[0-9]|0?50)", //0-50
    PHONE: "([*,#,D,P,0-9]*( )*)", //P,D,*,#, and 0-9
    ACCOUNT_NUMBER:
      "((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])",
    LOCKOUT_CODE:
      "((?!00000)[0-5]?[0-9]{0,4}|6[0-4][0-9]{0,3}|65[0-4][0-9]{0,2}|655[0-2][0-9]|6553[0-5])",
    NPHASE_VERIZON_LTE_ICCID: "^891480.{14}$",
    NPHASE_ATT_LTE_ICCID: "^8901.{16}$",
    NPHASE_VERIZON_V1_LTE_IMEI: "^35515508\\d{7}$",
    NPHASE_QUECTEL_LTE_IMEI:
      "^86642503\\d{7}$|^86844603\\d{7}$|^86206104\\d{7}$|^86278504\\d{7}$|^86443104\\d{7}$|^86703504\\d{7}$|^890141\\d{9}$|^8946\\d{11}$",
    ATT_DIRECT_ICCID: "^8901170.{13}$",
    ACCOUNT_PREFIX: "^[1-9]$|^[1-9][0-9]$|^0[1-9]$",
    EMAIL: "^w+([.-]?w+)*@w+([.-]?w+)*(.w{2,3})+$",
  })
  .constant("LTE_CARRIER_TYPE", {
    NONE: 0, //not LTE
    VERIZON_V1: 1, //Telit Modem Verizon LTE
    VERIZON_V2: 2, //Quectell Modem Verizon LTE
    ATT_V1: 3, //Quectell Modem ATT LTE
    ATT_V2: 4, //ATT Direct LTE
  })
  .constant("INPUT_PROPERTIES", {
    PROGRAMMING_DEBOUNCE_MS: 0,
    PROGRAMMING_BLUR_MS: 0,
    SHORT_DEBOUNCE_MS: 100,
  })
  .constant("DMP_EMPLOYEE_PROPERTIES", {
    TECH_SUPPORT_EMAIL: "techsupport@dmp.com",
    AUDIT_DISPLAY_ROLE: "DMP Employee",
    CACHE_SYNC_DISPLAY_NAME: "Auto Programming Sync",
    PROG_ARCHIVE_DISP_NAME: "Backups",
    AUTOMATIC_PROGRAMMING: "Automatic Programming",
  })
  .constant("IMAGE_SIZES", {
    x_large: { URLParameter: "EXTRA_LARGE", suffix: "_1920w_1080h_max" },
    large: { URLParameter: "LARGE", suffix: "_1024w_576h_max" },
    tablet: { URLParameter: "TABLET", suffix: "_768w_432h_max" },
    mobile_lg: { URLParameter: "MOBILE_LARGE", suffix: "_425w_239h_max" },
    mobile_med: { URLParameter: "MOBILE_MEDIUM", suffix: "_375w_320h_max" },
    mobile_sm: { URLParameter: "MOBILE_SMALL", suffix: "_320w_180h_max" },
    thumb: { URLParameter: "THUMB", suffix: "_100w_100h_max" },
  })
  .constant("MAP_SETTINGS", {
    INITIAL_ZOOM: 3,
    MAX_BOUNDING_ZOOM: 14,
    DEFAULT_LATITUDE: 39.833333,
    DEFAULT_LONGITUDE: -98.583333,
    MAP_STYLES: {
      LIGHT: [
        {
          featureType: "administrative",
          elementType: "labels.text.fill",
          stylers: [{ color: "#444444" }],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#b4a5a5" }],
        },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.stroke",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [{ color: "#f2f2f2" }],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [{ saturation: "-100" }, { lightness: "57" }],
        },
        {
          featureType: "poi",
          elementType: "geometry.stroke",
          stylers: [{ lightness: "1" }],
        },
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "all",
          stylers: [{ saturation: -100 }, { lightness: 45 }],
        },
        {
          featureType: "road.highway",
          elementType: "all",
          stylers: [{ visibility: "simplified" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [{ color: "#d2d2d2" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry.fill",
          stylers: [{ color: "#d3d3d3" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.fill",
          stylers: [{ color: "#868686" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#cfcfcf" }],
        },
        {
          featureType: "road.arterial",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road.local",
          elementType: "geometry.fill",
          stylers: [{ color: "#d1d1d1" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.fill",
          stylers: [{ color: "#949494" }],
        },
        {
          featureType: "road.local",
          elementType: "labels.text.stroke",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit.station.bus",
          elementType: "all",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit.station.bus",
          elementType: "labels.text.fill",
          stylers: [
            { saturation: "0" },
            { lightness: "0" },
            { gamma: "1.00" },
            { weight: "1" },
          ],
        },
        {
          featureType: "transit.station.bus",
          elementType: "labels.icon",
          stylers: [
            { saturation: "-100" },
            { weight: "1" },
            { lightness: "0" },
          ],
        },
        {
          featureType: "transit.station.rail",
          elementType: "all",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.text.fill",
          stylers: [{ gamma: "1" }, { lightness: "40" }, { color: "#7b7b7b" }],
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.text.stroke",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "transit.station.rail",
          elementType: "labels.icon",
          stylers: [{ saturation: "-100" }, { lightness: "30" }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#d2d2d2" }, { visibility: "on" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#a7c9e1" }],
        },
      ],
      DARK: [
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "all",
          elementType: "labels.text.stroke",
          stylers: [
            { visibility: "on" },
            { color: "#424b5b" },
            { weight: 2 },
            { gamma: "1" },
          ],
        },
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [{ weight: 0.6 }, { color: "#545b6b" }, { gamma: "0" }],
        },
        {
          featureType: "landscape",
          elementType: "geometry",
          stylers: [{ color: "#545b6b" }, { gamma: "1" }, { weight: "10" }],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [{ color: "#666c7b" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#545b6b" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#424a5b" }, { lightness: "0" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#666c7b" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#2e3546" }],
        },
      ],
    },
    CLUSTER: {
      MAX_ZOOM: 16,
      STYLES: [
        {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          textColor: "#fff36f",
          fontStyle: "normal",
          fontWeight: "normal",
          url: "assets/img/googlemaps-markers/m1.png", // a slash at the beginning of the url may work locally but will not in techApp
          height: 52,
          width: 52,
        },
        {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          textColor: "#fff36f",
          fontStyle: "normal",
          fontWeight: "normal",
          url: "assets/img/googlemaps-markers/m2.png",
          height: 56,
          width: 56,
        },
        {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          textColor: "#fff36f",
          fontStyle: "normal",
          fontWeight: "normal",
          url: "assets/img/googlemaps-markers/m3.png",
          height: 66,
          width: 66,
        },
        {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          textColor: "#fff36f",
          fontStyle: "normal",
          fontWeight: "normal",
          url: "assets/img/googlemaps-markers/m4.png",
          height: 78,
          width: 78,
        },
        {
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
          textColor: "#fff36f",
          fontStyle: "normal",
          fontWeight: "normal",
          url: "assets/img/googlemaps-markers/m5.png",
          height: 90,
          width: 90,
        },
      ],
    },
    SPIDERFIER: {
      LEG_COLOR: "#efff5b",
      MARKERS_WONT_MOVE: true,
      MARKERS_WONT_HIDE: true,
      BASIC_FORMAT_EVENTS: false,
      CIRCLE_SPIRAL_SWITCHOVER: 12,
      CIRCLE_FOOT_SEPARATION: 36,
      SPIRAL_LENGTH_FACTOR: 7,
    },
  })
  .constant("TEMP_DEALER_APP_USER", {
    BUTTON_LABEL: "Log In As Customer",
    DEFAULT_HOURS_TO_LIVE: 1,
    SPECIAL_TECH_SUPPORT_HOURS_TO_LIVE: 72,
    AUTHORIZE_BUTTON_LABEL: "Get Support",
  })
  .constant("MIN_VIEW_SEARCH", {
    NUM: 10,
  })
  .constant("TIMEOUTS", {
    REDUNDANCY: 1000,
  })
  .constant("DAYS_FOR_COMP_PROGRAMING", {
    NUM: 30,
  })
  .constant("XF6_MAX_USER_CODES", {
    NUM: 10,
  })
  .constant("CUSTOMER_ENGAGEMENT_CAMPAIGN_TYPE", {
    SecurityUpdate: 8, //Depricated SSP-3221
    Automation: 0,
    AppUserActivity: 1,
    VideoDoorbell: 2,
    VideoCameras: 3,
    UserCodeManagment: 4,
    SensorActivity: 5,
    VideoVerification: 6,
    ThreeGSunset: 7, //Depricated
  })
  .constant("REM_UNIT_RATIO", {
    NUM: 10,
  })
  .constant("DA_LOGIN_BACKGROUNDS", {
    securityCommand:
      "url('/assets/img/sc-login-backgrounds/LoginScreen_SecurityCommand.jpg')",
    default: "url('/assets/img/login-backgrounds/LoginScreen_DaDefault.jpg')",
  })
  .constant("DA_LOGOS", {
    securityCommand:
      "/assets/img/sc-logos/SecurityCommandLogo_Horizontal_Color.svg",
    default: "/assets/img/da-logos/DealerAdmin_Color.svg",
  })
  .constant("COMM_TEST_RESPONSE_MESSAGES", {
    CELL: {
      0: "Please Wait", //UPDATE
      1: "Modem Operating", //UPDATE
      2: "Identified", //UPDATE
      3: "Tower Detected", //UPDATE
      4: "Registered", //UPDATE
      5: "APN Accepted", //UPDATE
      6: "Comm Path Good", //SUCCESS
      7: "No Modem Found", //FAIL
      8: "No SIM card", //FAIL
      9: "No Tower", //FAIL
      10: "Not Registered", //FAIL
      11: "APN Error", //FAIL
      12: "No ACK Received", //FAIL
      13: "Not activated", //FAIL
      14: "Connect Error", //FAIL
      15: "No Signal", //FAIL
    },
    NET: {
      0: "Link OK", //UPDATE
      1: "DHCP OK", //UPDATE
      2: "Gateway Found", //UPDATE
      3: "Destination Found", //UPDATE
      4: "Network Communication Good", //SUCCESS
      5: "Link Error", //FAIL
      6: "DHCP Error", //FAIL
      7: "No Gateway", //FAIL
      8: "No Destination", //FAIL
      9: "Not Connected", //FAIL
      10: "Remote Connect", //FAIL
      11: "Invalid Port", //FAIL
      12: "No ACK Received", //FAIL
    },
  });
