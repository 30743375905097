import { between } from "app/common/services/util";
import graphql from "babel-plugin-relay/macro";
import { isNotNullOrUndefined } from "common/utils/universal/function";
import { setDifference, setFirst } from "common/utils/universal/set";
import CameraDetailsModal from "components/CameraEditCommon/CameraDetailsModal";
import {
  AnalyticsHeader,
  CameraDetailsContainer,
  DetectionContainer,
  Grid,
  Header,
  InnerHeader,
  InputContainer,
  InputLabel,
  MainContainerHeading,
  MainEdit,
  MainSettings,
  MainSettingsContainer,
  RecorderCameraEditVideoNotViewable,
  SettingsTitles,
  Snapshot,
  SnapshotContainer,
  SnapshotFrame,
} from "components/CameraEditCommon/CameraEditStyledComponents";
import { GenericPageFallback } from "components/GenericPageFallback";
import { DeleteConfirmModal } from "components/Modal/ConfirmModal";
import RelayEnvironmentCloneProvider from "components/RelayEnvironmentCloneProvider";
import { Switch } from "components/SiteForm/FormFields";
import Tag from "components/Tag";
import {
  $RootScope,
  AlertsContextProvider,
  useShowAlert,
} from "contexts/AlertsContext";
import { range } from "ramda";
import * as React from "react";
import {
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { react2angular } from "react2angular";
import { Environment, readInlineData, RecordProxy } from "relay-runtime";
import {
  asID,
  fromVarConnectedCameraId,
  fromVarHubCameraId,
  fromVarHubId,
  idAsString,
  toVarConnectedCameraId,
  toVarHubCameraDetectionRegionId,
  VarConnectedCamera,
  VarHubCameraDetectionRegion,
  VarHubCameraRegionGeometry,
} from "securecom-graphql/client";
import AddButton from "../CameraEditCommon/AddButton";
import CameraEditOptions from "../CameraEditCommon/CameraEditOptions";
import DetectionRegions from "./RecorderCameraDetectionRegions";
import RecorderCameraDetectionRegionSettings from "./RecorderCameraDetectionRegionSettings";
import { RecorderCameraEditQuery } from "./__generated__/RecorderCameraEditQuery.graphql";
import { RecorderCameraEditRemoveFromVarHubEnabledCameraListMutation } from "./__generated__/RecorderCameraEditRemoveFromVarHubEnabledCameraListMutation.graphql";
import { RecorderCameraEditSaveMutation } from "./__generated__/RecorderCameraEditSaveMutation.graphql";
import { RecorderCameraEdit_varHubCamera$key } from "./__generated__/RecorderCameraEdit_varHubCamera.graphql";
import { RecorderCameraEdit_varHubCameraDetectionRegion$key } from "./__generated__/RecorderCameraEdit_varHubCameraDetectionRegion.graphql";

const saveMutation = graphql`
  mutation RecorderCameraEditSaveMutation(
    $input: UpdateVarHubCameraInput!
    $removedDetectionRegionIds: [ID!]
  ) {
    updateVarHubCamera(
      input: $input
      removedDetectionRegionIds: $removedDetectionRegionIds
    ) {
      ... on UpdateVarHubCameraSuccessPayload {
        varHubCamera {
          ...RecorderCameraEdit_varHubCamera
        }
      }
      ... on UpdateVarHubCameraErrorPayload {
        error {
          type
        }
      }
    }
  }
`;

const removeCameraMutation = graphql`
  mutation RecorderCameraEditRemoveFromVarHubEnabledCameraListMutation(
    $systemId: String!
    $hubId: String!
    $removal: RemoveFromVarHubEnabledCamerasListInput!
  ) {
    removeFromVarHubEnabledCamerasList(
      hubId: $hubId
      systemId: $systemId
      removal: $removal
    ) {
      ... on RemoveFromVarHubEnabledCamerasListSuccessPayload {
        hubs {
          camectHubId
          hubId
          cameras {
            addedToDB
            camectCamId
            camectHubId
            cameraId
            cameraName
            ipAddress
            isEnabled
            isStreaming
            macAddress
            needsCredential
            playerAuthToken
            playerUrl
            snapshot
          }
        }
        __typename
      }
      ... on RemoveFromVarHubEnabledCamerasListErrorPayload {
        type
        message
        __typename
      }
    }
  }
`;

const detectionRegionInlineFragment = graphql`
  fragment RecorderCameraEdit_varHubCameraDetectionRegion on VarHubCameraDetectionRegion
  @inline {
    id
    isNew
    name
    detectAnimals
    detectPeople
    detectVehicles
    drawRegionOnAlerts
    loiteringPersonSeconds
    slotNumber
    zone {
      id
      name
      number
      area {
        id
      }
    }
    geometry {
      type
      coordinates
    }
  }
`;

function RecorderCameraEditForm(props: {
  systemId: string;
  cameraId: string;
  camera: RecorderCameraEdit_varHubCamera$key;
  canDelete: boolean;
  isEditable: boolean;
  onCancel: () => void;
}) {
  const { systemId, cameraId, camera, canDelete, isEditable, onCancel } = props;
  const data = useFragment(
    graphql`
      fragment RecorderCameraEdit_varHubCamera on VarConnectedCamera {
        id
        databaseCameraId
        cameraName
        availableInVideoVerification
        camectCameraId
        camectHubId
        macAddress
        snapshot
        megapixels
        detectionRegions {
          id
          slotNumber
          zone {
            number
          }
          ...RecorderCameraDetectionRegionSettings_varHubCameraDetectionRegion
          ...RecorderCameraEdit_varHubCameraDetectionRegion
        }
        varHub {
          id
          maxDetectionRegions
          isOnline
          ...RecorderCameraDetectionRegionSettings_varHub
          cameras {
            id
            ipAddress
            isStreaming
            adminUserName
            adminPassword
          }
        }
        controlSystem {
          id
          varHubDetectionRegionZoneNumberMax
          varHubDetectionRegionZoneNumberMin
          dealerAccess {
            accessType
          }
          panel {
            ...RecorderCameraDetectionRegionSettings_panel
            deviceInformations {
              ... on XrDeviceInformation {
                axNumber
                deviceType
                deviceCommunicationMethod
              }
            }
          }
          servicesManager {
            videoVerificationEnabled
          }
          customer {
            dealer {
              vernaculars {
                scapiId
                dealerId
                original
                replacement
              }
              securityCommand
            }
          }
        }
        ...RecorderCameraDetectionRegionSettings_varHubCamera
        ...RecorderCameraDetectionRegions_varHubCamera
      }
    `,
    camera
  );
  const relayEnv = useRelayEnvironment();
  const maxDetectionRegions = data.varHub?.maxDetectionRegions ?? 4;
  const varHubCamera = data.varHub?.cameras.find(({ id }) => {
    return (
      fromVarHubCameraId(asID(data.id)).camectCameraId ===
      fromVarConnectedCameraId(asID(id)).camectCameraId
    );
  });

  const showAlert = useShowAlert();
  const [save, isSaving] =
    useMutation<RecorderCameraEditSaveMutation>(saveMutation);

  const [removeFromCamerasList, removingFromCamerasList] =
    useMutation<RecorderCameraEditRemoveFromVarHubEnabledCameraListMutation>(
      removeCameraMutation
    );

  const [disableModalOpen, setDisableModalOpen] = React.useState(false);
  const [cameraDetailsModalOpen, setCameraDetailsModalOpen] =
    React.useState(false);

  const [snapshotAspectRatio, setSnapshotAspectRatio] = React.useState<
    number | null
  >(() => null);
  const [removedDetectionRegionIds, setRemovedDetectionRegionIds] =
    React.useState(() => new Set<string>());

  const regionZoneNumbers = React.useMemo(
    () =>
      data.detectionRegions
        .map((region) =>
          region.zone ? parseInt(region.zone.number, 10) : null
        )
        .filter(isNotNullOrUndefined),
    [data.detectionRegions]
  );

  const regionSlotNumbers = React.useMemo(
    () =>
      new Set(
        data.detectionRegions
          .map((region) => region.slotNumber)
          .filter(isNotNullOrUndefined)
      ),
    [data.detectionRegions]
  );

  const axTakenZoneNumbers = React.useMemo(() => {
    return new Set(
      data?.controlSystem?.panel?.deviceInformations
        ? data.controlSystem.panel.deviceInformations.flatMap((device) => {
            const number = Number(device.axNumber);
            switch (true) {
              case device.deviceType === "CAMERA" ||
                device.deviceType === "VPLEX":
                // this is done so camera and vplex dont reserve the entire bus when they dont need to
                return [];
              case between(number, 17, 32):
                return range(500, 600);
              case between(number, 33, 48):
                return range(600, 700);
              case between(number, 49, 64):
                return range(700, 800);
              case between(number, 65, 80):
                return range(800, 900);
              case between(number, 81, 96):
                return range(900, 1000);
              default:
                return [];
            }
          })
        : []
    );
  }, [data.controlSystem?.panel.deviceInformations]);

  function findDuplicateZones(zoneNumbers: number[]) {
    const uniqueElements = new Set(zoneNumbers);
    // eslint-disable-next-line array-callback-return
    const filteredElements = zoneNumbers.filter((item) => {
      if (uniqueElements.has(item)) {
        uniqueElements.delete(item);
      } else {
        return item;
      }
    });

    return [...new Set(filteredElements)];
  }

  function findZonesOutOfRange(
    zoneNumbers: number[],
    min: number,
    max: number
  ) {
    return zoneNumbers.filter((zoneNumber) => {
      return zoneNumber < min || zoneNumber > max;
    });
  }
  const zoneMin =
    data?.controlSystem?.varHubDetectionRegionZoneNumberMin ?? 500;
  const zoneMax =
    data?.controlSystem?.varHubDetectionRegionZoneNumberMax ?? 999;

  const invalidZoneNumbers = React.useMemo(
    () =>
      findDuplicateZones(
        regionZoneNumbers.concat([...axTakenZoneNumbers])
      ).concat(findZonesOutOfRange(regionZoneNumbers, zoneMin, zoneMax)),
    [regionZoneNumbers, axTakenZoneNumbers, zoneMin, zoneMax]
  );

  const detectionRegionTypeNotSelected = () =>
    data.detectionRegions.some((region) => {
      const regionData =
        readInlineData<RecorderCameraEdit_varHubCameraDetectionRegion$key>(
          detectionRegionInlineFragment,
          region
        );
      return !(
        regionData.detectAnimals ||
        regionData.detectPeople ||
        regionData.detectVehicles
      );
    });

  const vernaculars =
    data.controlSystem?.customer.dealer.vernaculars.map((v) => ({ ...v })) ??
    [];

  const appName = data.controlSystem?.customer.dealer.securityCommand
    ? "Security Command"
    : "Dealer Admin";

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (detectionRegionTypeNotSelected()) {
        } else {
          save({
            variables: {
              input: {
                id: data.id,
                cameraDatabaseId: data.databaseCameraId,
                cameraName: data.cameraName,
                availableInVideoVerification: data.availableInVideoVerification,
                detectionRegions: data.detectionRegions.map((region, index) => {
                  const regionData =
                    readInlineData<RecorderCameraEdit_varHubCameraDetectionRegion$key>(
                      detectionRegionInlineFragment,
                      region
                    );
                  return {
                    id: regionData.isNew ? null : regionData.id,
                    name: regionData.name,
                    detectAnimals: regionData.detectAnimals,
                    detectPeople: regionData.detectPeople,
                    detectVehicles: regionData.detectVehicles,
                    drawRegionOnAlerts: regionData.drawRegionOnAlerts,
                    geometry: regionData.geometry,
                    loiteringPersonSeconds: regionData.loiteringPersonSeconds,
                    slotNumber: regionData.slotNumber ?? index,
                    zone: regionData.zone && {
                      id: regionData.zone.id,
                      number: Number(regionData.zone.number),
                      name: regionData.zone.name,
                      areaId: regionData.zone.area?.id,
                    },
                  };
                }),
              },
              removedDetectionRegionIds: removedDetectionRegionIds.size
                ? [...removedDetectionRegionIds]
                : undefined,
            },
            updater: (store) => {
              const detectionRegions = store
                .get<VarConnectedCamera>(data.id)
                ?.getLinkedRecords("detectionRegions");
              if (detectionRegions) {
                detectionRegions.forEach((region, index) => {
                  region.setValue(index, "index");
                });
              }
            },
            onCompleted: (result) => {
              setRemovedDetectionRegionIds(new Set());

              const { varHubCamera, error } = result.updateVarHubCamera;
              if (varHubCamera) {
                onCancel();
                showAlert({
                  type: "success",
                  text: "Camera saved",
                });
              } else {
                showAlert({
                  type: "error",
                  text:
                    error?.type === "CONNECTION_TIMEOUT"
                      ? "Failed to save: Connection Timeout"
                      : "Failed to save",
                });
              }
            },
            onError: () => {
              showAlert({
                type: "error",
                text: "Failed to save",
              });
            },
          });
        }
      }}
    >
      <Header>
        <div className="row">
          <div className="page-header">
            <div className="page-header__left">
              <div className="page-header__title">
                <span>{`Editing ${
                  data.cameraName ? data.cameraName : `Camera`
                }`}</span>
              </div>
            </div>
            <div className="page-header__right">
              <button
                type="button"
                className="btn btn-default btn-sm"
                onClick={onCancel}
              >
                Back
              </button>
              {canDelete ? (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => setDisableModalOpen(!disableModalOpen)}
                  disabled={isSaving}
                >
                  Disable
                </button>
              ) : null}
              {isEditable ? (
                <button
                  type="submit"
                  className="btn btn-dmp btn-sm"
                  disabled={
                    invalidZoneNumbers.length > 0 ||
                    isSaving ||
                    !data.cameraName
                  }
                >
                  Save
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </Header>
      <Grid>
        <MainEdit>
          <InnerHeader>
            <InputContainer>
              <InputLabel>
                Camera Name<span className="text-danger">*</span>
              </InputLabel>
              <input
                type="text"
                name="name"
                className="form-control form-control-300"
                disabled={!isEditable}
                required
                value={data.cameraName}
                onChange={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<VarConnectedCamera>;
                    camera.setValue(event.target.value, "cameraName");
                  });
                }}
                onBlur={(event) => {
                  relayEnv.commitUpdate((store) => {
                    const camera = store.get(
                      data.id
                    ) as RecordProxy<VarConnectedCamera>;
                    camera.setValue(event.target.value.trim(), "cameraName");
                  });
                }}
              />
            </InputContainer>
            <CameraDetailsContainer>
              <button
                type="button"
                className="btn btn-dmp btn-sm"
                onClick={() => setCameraDetailsModalOpen(true)}
              >
                Camera Details
              </button>
            </CameraDetailsContainer>
          </InnerHeader>
          <MainContainerHeading></MainContainerHeading>
          <MainSettingsContainer>
            <fieldset disabled={!isEditable}>
              <MainSettings>
                {!!data.controlSystem?.servicesManager
                  .videoVerificationEnabled && (
                  <>
                    <SettingsTitles>Options</SettingsTitles>
                    <CameraEditOptions
                      label="Available for Monitoring Center Video Verification"
                      info="Enable this camera's stream for Monitoring Center Video Verification"
                    >
                      <Switch
                        label="Available for Monitoring Center Video Verification"
                        checked={data.availableInVideoVerification}
                        onChange={() => {
                          relayEnv.commitUpdate((store) => {
                            const camera = store.get(
                              data.id
                            ) as RecordProxy<VarConnectedCamera>;
                            camera.setValue(
                              !data.availableInVideoVerification,
                              "availableInVideoVerification"
                            );
                          });
                        }}
                      />
                    </CameraEditOptions>
                  </>
                )}
                <AnalyticsHeader>
                  <SettingsTitles>
                    Regions & Analytics
                    <Tag variant="success">
                      {`${
                        maxDetectionRegions - data.detectionRegions.length
                      } available`}
                    </Tag>
                  </SettingsTitles>
                  <AddButton
                    type="button"
                    disabled={
                      data.detectionRegions.length >= maxDetectionRegions
                    }
                    onClick={() => {
                      relayEnv.commitUpdate((store) => {
                        const camera = store.get(
                          data.id
                        ) as RecordProxy<VarConnectedCamera>;
                        const newId = toVarHubCameraDetectionRegionId(
                          systemId,
                          cameraId,
                          Date.now()
                        );
                        const availableSlotNumbers = setDifference(
                          regionSlotNumbers,
                          new Set(range(0, maxDetectionRegions))
                        ) as Set<number>;
                        const nextSlotNumber = setFirst(availableSlotNumbers);
                        const newRegion = store.create(
                          idAsString(newId),
                          "VarHubCameraDetectionRegion"
                        ) as RecordProxy<VarHubCameraDetectionRegion>;
                        newRegion.setValue(idAsString(newId), "id");
                        newRegion.setValue(true, "isNew");
                        newRegion.setValue("", "name");
                        newRegion.setValue(false, "detectPeople");
                        newRegion.setValue(false, "detectAnimals");
                        newRegion.setValue(false, "detectVehicles");
                        newRegion.setValue(false, "drawRegionOnAlerts");
                        newRegion.setValue(0, "loiteringPersonSeconds");
                        newRegion.setValue(nextSlotNumber, "slotNumber");
                        newRegion.setValue(null, "zone");
                        newRegion.setValue(
                          data.detectionRegions?.length ?? 0,
                          "index"
                        );

                        const geometry = store.create(
                          `${newId}:geometry`,
                          "VarHubCameraRegionGeometry"
                        ) as RecordProxy<VarHubCameraRegionGeometry>;
                        geometry.setValue("POLYGON", "type");
                        geometry.setValue(
                          [
                            [
                              [0.3, 0.3],
                              [0.7, 0.3],
                              [0.7, 0.7],
                              [0.3, 0.7],
                              [0.3, 0.3], // The first and last point must always be identical
                            ],
                          ],
                          "coordinates"
                        );
                        newRegion.setLinkedRecord(geometry, "geometry");

                        camera.setLinkedRecords(
                          [
                            ...camera.getLinkedRecords("detectionRegions"),
                            newRegion,
                          ],
                          "detectionRegions"
                        );
                      });
                    }}
                  >
                    <span>Region</span>
                  </AddButton>
                </AnalyticsHeader>
                <div>
                  {data.detectionRegions.map(
                    (region, index) =>
                      data.controlSystem && (
                        <RecorderCameraDetectionRegionSettings
                          key={region.id}
                          index={index}
                          camera={data}
                          detectionRegion={region}
                          globalSystemId={data.controlSystem.id}
                          isEditable={isEditable}
                          systemId={systemId}
                          panel={data.controlSystem.panel}
                          varHub={data.varHub}
                          setRemovedDetectionRegionIds={
                            setRemovedDetectionRegionIds
                          }
                          regionZoneNumbers={regionZoneNumbers}
                        />
                      )
                  )}
                </div>
              </MainSettings>
            </fieldset>
            {!document.getElementById("expo") && (
              <DetectionContainer>
                <SnapshotFrame aspectRatio={snapshotAspectRatio ?? 4 / 3}>
                  <SnapshotContainer>
                    {data?.controlSystem?.dealerAccess?.accessType ===
                    "denied" ? (
                      <RecorderCameraEditVideoNotViewable
                        vernaculars={vernaculars}
                        appName={appName}
                      />
                    ) : (
                      <>
                        <Snapshot
                          src={data.snapshot ?? ""}
                          onLoad={(event) => {
                            if (event.target instanceof HTMLImageElement) {
                              setSnapshotAspectRatio(
                                event.target.width / event.target.height
                              );
                            }
                          }}
                        />
                        {isNotNullOrUndefined(snapshotAspectRatio) && (
                          <DetectionRegions
                            aspectRatio={snapshotAspectRatio ?? 4 / 3}
                            camera={data}
                            isEditable={isEditable}
                          />
                        )}
                      </>
                    )}
                  </SnapshotContainer>
                </SnapshotFrame>
              </DetectionContainer>
            )}
          </MainSettingsContainer>
        </MainEdit>
      </Grid>
      {disableModalOpen && (
        <DeleteConfirmModal
          actionPending={removingFromCamerasList}
          header="Disable Camera Confirmation"
          cancelText="Cancel"
          confirmText="Disable"
          pendingText="Disabling..."
          onConfirm={() =>
            removeFromCamerasList({
              variables: {
                hubId: fromVarHubId(asID(data.varHub?.id ?? "")).hubId,
                systemId: data.controlSystem?.id ?? "",
                removal: {
                  removeCamectCameraId: [data.camectCameraId],
                },
              },
              onCompleted: (res) => {
                if (
                  res.removeFromVarHubEnabledCamerasList.__typename ===
                  "RemoveFromVarHubEnabledCamerasListErrorPayload"
                ) {
                  showAlert({
                    type: "error",
                    text: `Error Updating Camera List.`,
                  });
                } else {
                  showAlert({
                    type: "success",
                    text: `Successfully updated camera list.`,
                  });
                  onCancel();
                }

                setDisableModalOpen(false);
              },
              onError: (error) => {
                showAlert({
                  type: "error",
                  text: `Error updating camera List.`,
                });
              },
            })
          }
          onCancel={() => {
            setDisableModalOpen(false);
          }}
        >
          Are you sure you want to disable this Camera?
        </DeleteConfirmModal>
      )}
      {cameraDetailsModalOpen && (
        <CameraDetailsModal
          cameraId={data.id}
          status={!!data.varHub?.isOnline && !!varHubCamera?.isStreaming}
          cameraName={data.cameraName}
          macAddress={data.macAddress}
          ipAddress={varHubCamera?.ipAddress}
          resolution={data.megapixels}
          userName={
            varHubCamera?.adminUserName !== null
              ? varHubCamera?.adminUserName
              : ""
          }
          password={
            varHubCamera?.adminPassword !== null
              ? varHubCamera?.adminPassword
              : ""
          }
          onCancel={() => setCameraDetailsModalOpen(false)}
        />
      )}
    </form>
  );
}

const RecorderCameraEdit = (props: {
  systemId: string;
  hubId: string;
  cameraId: string;
  $state: any;
  UserService: any;
}) => {
  const { systemId, hubId, cameraId, $state, UserService } = props;

  const data = useLazyLoadQuery<RecorderCameraEditQuery>(
    graphql`
      query RecorderCameraEditQuery($cameraId: ID!) {
        camera: node(id: $cameraId) {
          ... on VarConnectedCamera {
            ...RecorderCameraEdit_varHubCamera
          }
        }
      }
    `,
    { cameraId: idAsString(toVarConnectedCameraId(systemId, hubId, cameraId)) },
    { fetchPolicy: "network-only" }
  );

  const goToControlSystemRoute = () => {
    $state.go("app.control_system", {
      customer_id: UserService.customer_id,
      control_system_id: UserService.control_system_id,
    });
  };

  React.useEffect(() => {
    if (!data.camera) {
      goToControlSystemRoute();
    }
    // eslint-disable-next-line
  }, []);

  return data.camera ? (
    <RelayEnvironmentCloneProvider>
      <RecorderCameraEditForm
        systemId={systemId}
        cameraId={cameraId}
        camera={data.camera}
        canDelete={UserService.canDeleteVideoDevice()}
        isEditable={UserService.canEditVideoDevice()}
        onCancel={goToControlSystemRoute}
      />
    </RelayEnvironmentCloneProvider>
  ) : null;
};

function RecorderCameraEditRoot(
  props: React.ComponentProps<typeof RecorderCameraEdit> & {
    RelayService: { getEnvironment: () => Environment };
    $rootScope: $RootScope;
  }
) {
  return (
    <React.Suspense fallback={<GenericPageFallback />}>
      <RelayEnvironmentProvider
        environment={props.RelayService.getEnvironment()}
      >
        <AlertsContextProvider $rootScope={props.$rootScope}>
          <RecorderCameraEdit {...props} />
        </AlertsContextProvider>
      </RelayEnvironmentProvider>
    </React.Suspense>
  );
}

export function dangerouslyAddToApp() {
  App.component(
    "recorderCameraEdit",
    react2angular(
      RecorderCameraEditRoot,
      ["systemId", "hubId", "cameraId"],
      ["$rootScope", "$scope", "$state", "UserService", "RelayService"]
    )
  );
}
