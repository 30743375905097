App.controller("CameraStatusReportCtrl", [
  "$scope",
  "$q",
  "$filter",
  "$compile",
  "$rootScope",
  "UserService",
  "DashboardDataService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "PanelDataService",
  "$stateParams",
  "DataTablesUtilsService",
  "$sanitize",
  function (
    $scope,
    $q,
    $filter,
    $compile,
    $rootScope,
    UserService,
    DashboardDataService,
    DTOptionsBuilder,
    DTColumnBuilder,
    PanelDataService,
    $stateParams,
    DataTablesUtilsService,
    $sanitize
  ) {
    $scope.UserService = UserService;
    $scope.dealerID = UserService.dealer_id;
    var reportType = $stateParams.report_type;
    $scope.reportType = reportType;
    $scope.dtOptions = DataTablesUtilsService.getDTOptions(
      cameraInfo(),
      "Cameras"
    );
    $scope.dtColumns = DataTablesUtilsService.dtColumns;

    $scope.dtOptions.withOption("order", [0, "asc"], [1, "desc"]);

    const customerReplacement =
      UserService.dealerInfo.vernaculars.customers.replacement || "Customer";
    const systemReplacement =
      UserService.dealerInfo.vernaculars.systems.replacement || "System";

    function cameraInfo() {
      var deferred = $q.defer();
      var cameraSelection;
      $scope.selectedReport = "";

      switch (reportType) {
        case "all":
          cameraSelection = DashboardDataService.getVideoDevicesForDealer(
            $scope.dealerID
          );
          $scope.selectedReport = "All Cameras";
          break;
        case "offline":
          cameraSelection = DashboardDataService.camerasNotOnlineNow(
            $scope.dealerID
          );
          $scope.selectedReport = "Cameras Not Online";
          break;
        case "online":
          cameraSelection = DashboardDataService.camerasOnlineNow(
            $scope.dealerID
          );
          $scope.selectedReport = "Cameras Online";
          break;
      }
      if (angular.isUndefined(cameraSelection, $scope.selectedReport)) {
        deferred.reject();
      } else {
        cameraSelection
          .then(
            function (data) {
              deferred.resolve(data);
            },
            function (error) {
              deferred.reject(error);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
      }
      return deferred.promise;
    }

    $scope.dtColumns = [
      DTColumnBuilder.newColumn("control_system.customer.name").withTitle(
        customerReplacement
      ),
      DTColumnBuilder.newColumn("control_system.name").withTitle(
        systemReplacement
      ),
      DTColumnBuilder.newColumn("name")
        .withTitle("Camera Name")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.formatSystemName(row);
        }),
      DTColumnBuilder.newColumn("control_system.customer.email")
        .withTitle(`${customerReplacement} Email`)
        .notVisible(),
      DTColumnBuilder.newColumn(null)
        .withTitle("Network Ready")
        .renderWith(function (data, type, row) {
          return DataTablesUtilsService.asBoolean(row, "network_ready");
        }),
      DTColumnBuilder.newColumn("mac_address").withTitle("MAC Address"),
      DTColumnBuilder.newColumn("model").withTitle("Model"),
      DTColumnBuilder.newColumn("checked_in_at")
        .withTitle("Last Checked In")
        .renderWith(DataTablesUtilsService.asDateTimeRenderFn()),
      DTColumnBuilder.newColumn("control_system.postal_code")
        .withTitle("Postal Code")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.address_1")
        .withTitle("Address")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.city")
        .withTitle("City")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.state")
        .withTitle("State")
        .notVisible(),
      DTColumnBuilder.newColumn("control_system.country")
        .withTitle("Country")
        .notVisible(),
    ];
  },
]);
