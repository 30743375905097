App.service("DataTablesUtilsService", [
  "$q",
  "$filter",
  "PROPS",
  "UserService",
  "DealerODataAPI",
  "UserODataAPI",
  "OdataPageService",
  "DTOptionsBuilder",
  "DTColumnBuilder",
  "DashboardDataService",
  "$compile",
  "$sanitize",
  "JobSchedulerService",
  function (
    $q,
    $filter,
    PROPS,
    UserService,
    DealerODataAPI,
    UserODataAPI,
    OdataPageService,
    DTOptionsBuilder,
    DTColumnBuilder,
    DashboardDataService,
    $compile,
    $sanitize,
    JobSchedulerService
  ) {
    var _this = this;
    _this.UserService = UserService;
    _this.dealerID = UserService.dealer_id;

    /**
     * getDTOptions
     * @param dataPromise
     * @param itemType
     * @returns {*}
     * @description Set of generic Data Table options with various customizable options.
     */

    _this.getDTOptions = function (dataPromise, itemType) {
      itemType = typeof itemType !== "undefined" ? itemType : "Systems";
      return (
        DTOptionsBuilder.fromFnPromise(dataPromise)
          .withOption("stateSave", false)
          .withOption("deferRender", true)
          .withOption("language", getLangDefs(itemType))
          .withOption("order", [0, "asc"])

          // sets pagination to allow only a previous and next button
          .withPaginationType("simple_numbers")
          // sets initial display length of of 10 rows
          .withDisplayLength(10)
          // Excludes the first column from being hidden
          .withBootstrap()
          //sets location of tools in the DOM
          .withDOM(
            '<"data-table__top" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
          ) //<Tlpi>

          .withBootstrapOptions({
            sFilter: {
              classes: {
                input: "",
              },
            },
            pagination: {
              classes: {
                ul: "pagination pagination-sm",
              },
            },
          })
          .withButtons([
            {
              extend: "collection",
              className: "btn-sm",
              text: "Export",
              buttons: [
                "csv",
                "excel",
                {
                  extend: "pdf",
                  orientation: "landscape",
                  exportOptions: {
                    columns: ":visible",
                  },
                },
              ],
            },
            {
              extend: "colvis",
              className: "btn-sm",
              text: "Show/Hide Columns",
            },
          ])
      );
    };

    _this.getMassProgrammingDTOptions = function (dataPromise, itemType) {
      itemType = typeof itemType !== "undefined" ? itemType : "Systems";
      return (
        DTOptionsBuilder.fromFnPromise(dataPromise)
          .withOption("stateSave", false)
          .withOption("deferRender", true)
          .withOption("language", getLangDefs(itemType))
          .withOption("order", [0, "asc"])

          // sets pagination to allow only a previous and next button
          .withPaginationType("simple_numbers")
          // sets initial display length of of 10 rows
          .withDisplayLength(10)
          // Excludes the first column from being hidden
          .withBootstrap()
          //sets location of tools in the DOM
          .withDOM(
            '<"data-table__top" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
          ) //<Tlpi>

          .withBootstrapOptions({
            sFilter: {
              classes: {
                input: "",
              },
            },
            pagination: {
              classes: {
                ul: "pagination pagination-sm",
              },
            },
          })
          .withButtons([
            {
              extend: "colvis",
              className: "btn-sm",
              text: "Show/Hide Columns",
            },
          ])
      );
    };

    /**
     * getEmptyDTOptions
     * @param itemType
     * @returns {*}
     * @description Creates generic Data Table with empty rows
     */
    _this.getEmptyDTOptions = function (itemType) {
      itemType = typeof itemType !== "undefined" ? itemType : "Systems";
      return (
        DTOptionsBuilder.newOptions()
          .withOption("stateSave", false)
          .withOption("deferRender", true)
          .withOption("language", getLangDefs(itemType))
          .withOption("order", [0, "asc"])
          // sets pagination to allow only a previous and next button
          .withPaginationType("simple_numbers")
          // sets initial display length of of 10 rows
          .withDisplayLength(10)
          // Excludes the first column from being hidden
          .withBootstrap()
          //sets location of tools in the DOM
          .withDOM(
            '  <"data-table__top" <"table-search"search"rf> <"column-selector" B>><t><"data-table__bottom"<"data-table__bottom--left" i><"data-table__bottom--center"p><"data-table__bottom--right"l>>'
          ) //<Tlpi>
          .withBootstrapOptions({
            sFilter: {
              classes: {
                input: "input-xs",
              },
            },
            pagination: {
              classes: {
                ul: "pagination pagination-sm",
              },
            },
          })
          .withButtons([
            {
              extend: "collection",
              className: "btn-sm",
              text: "Export",
              buttons: [
                "csv",
                "excel",
                {
                  extend: "pdf",
                  orientation: "landscape",
                  exportOptions: {
                    columns: ":visible",
                  },
                },
              ],
            },
          ])
      );
    };

    /**
     * getLandDefs
     * @param itemType
     * @returns {{sEmptyTable: string, sInfo: string, sInfoEmpty: string, sInfoFiltered: string, sInfoPostFix: string, sInfoThousands: string, sLengthMenu: string, sLoadingRecords: string, sProcessing: string, sSearch: string, sZeroRecords: string, oPaginate: {sFirst: string, sLast: string, sNext: string, sPrevious: string}, oAria: {sSortAscending: string, sSortDescending: string}}}
     * @description collection of generic lang defs used in Data Tables
     */
    function getLangDefs(itemType) {
      const systemReplacement =
        UserService.dealerInfo.vernaculars.systems.replacement;
      let itemTypeReplaced = itemType;
      if (itemType === "Systems" && systemReplacement) {
        itemTypeReplaced = systemReplacement;
      }
      return {
        sSearchPlaceholder: "Search...",
        sEmptyTable:
          "<span class='text-primary' style='display: flex; justify-content: flex-start; align-items: center;'><i class='icon-radial_info'>&nbsp;</i> No data available in table</span>",
        sInfo: "Showing _START_ to _END_ of _TOTAL_ ",
        sInfoEmpty: "Showing 0 to 0 of 0 " + itemTypeReplaced,
        sInfoFiltered: "(filtered from _MAX_ total " + itemTypeReplaced + ")",
        sInfoPostFix: "",
        sInfoThousands: ",",
        sLengthMenu: "Rows" + "<span></span>  _MENU_ ",
        sLoadingRecords: "<span class='text-primary ml'>Loading...</span>",
        sProcessing: "Processing...",
        sSearch: "_INPUT_",

        sZeroRecords: "No matching records found",
        oPaginate: {
          sFirst: "First",
          sLast: "Last",
          sNext: "Next",
          sPrevious: "Previous",
        },
        oAria: {
          sSortAscending: ": activate to sort column ascending",
          sSortDescending: ": activate to sort column descending",
        },
      };
    }

    /**
     * createdRow
     * @param row
     * @param scope
     * @description Recompiles the data to be displayed in the data table
     */
    _this.createdRow = function (row, scope) {
      // Recompiling so we can bind Angular directive to the DT
      $compile(angular.element(row).contents())(scope);
    };

    /**
     * asBoolean
     * @param row
     * @param property
     * @returns {string}
     * @description Checks that row, property are boolean and replaces flag with checkmark if true.
     */
    _this.asBoolean = function (row, property) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      //vk api returns the string "false" for false values
      if (objectValue === false || objectValue === "false") {
        return '<i class="icon-dmp icon-close_cancel text-danger"><span style="display: none;">N</span></i>';
      } else {
        return '<i class="icon-dmp icon-checkmark text-success"><span style="display: none;">Y</span></i>';
      }
    };

    /**
     * asDateTime
     * @param row
     * @param property
     * @param format
     * @returns The formatted date/time HTML block.
     * @description Formats the property with date specified, or uses default provided.
     */
    _this.asDateTime = function (row, property, format) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      return _this.formatDateTimeAsHtml(objectValue, format);
    };

    /**
     * asDateTimeFormatted
     * @param row
     * @param property
     * @param format
     * @returns {*}
     * @description Formats the property with date specified, or uses default provided.
     */
    _this.asDateTimeFormatted = function (row, property, format) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      if (
        typeof objectValue === "undefined" ||
        objectValue == null ||
        objectValue == ""
      ) {
        return "-";
      } else if (typeof format === "undefined") {
        // Use a predefined date/time format
        return $filter("date")(objectValue, "short");
      } else {
        // Use specified date/time format
        return $filter("date")(objectValue, format);
      }
    };

    /**
     * asDateTimeRenderFn
     * @param format (Optional) The format to use when displaying the value.
     * @returns A datatables render function.
     * @description
     * Returns a render function for use by the datatables column.
     * The function will return the raw date/time value when sorting data, but will
     * return the formatted date/time HTML block when displaying data.
     */
    _this.asDateTimeRenderFn = function (format) {
      // Reference: https://datatables.net/reference/option/columns.render
      return function (data, type, row) {
        // Return the raw date/time when datatables wants the value to sort based on
        return type == "sort" ? data : _this.formatDateTimeAsHtml(data, format);
      };
    };

    /**
     * asLiteralDateTime
     * @param row
     * @param property
     * @param format
     * @returns {*}
     * @description Formats the property with date specified, forcing the browser to display the literal time of the stored date.
     */
    _this.asLiteralDateTime = function (row, property, format) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      if (
        typeof objectValue === "undefined" ||
        objectValue == null ||
        objectValue == ""
      ) {
        return "-";
      } else {
        if (typeof format === "undefined") format = "MM/dd/yyyy h:mm a";
        var date = new Date(objectValue);
        var dateLiteral = dateTimeForceUTC(date);
        return $filter("date")(dateLiteral, format);
      }
    };

    /**
     * asLiteralDateTimeRenderFn
     * @param property The property name of the data.
     * @param format The format to use.
     * @returns A datatables render function.
     * @description
     * Returns a render function for use by the datatables column.
     * The function will return the raw date/time value when sorting data, but will
     * return the formatted date/time when displaying data.
     */
    _this.asLiteralDateTimeRenderFn = function (property, format) {
      // Reference: https://datatables.net/reference/option/columns.render
      return function (data, type, row) {
        // Return the raw date/time when datatables wants the value to sort based on
        return type == "sort"
          ? data
          : _this.asLiteralDateTime(row, property, format);
      };
    };

    /**
     * asHardwareModel
     * @param row
     * @param property
     * @returns {*}
     * @description Checks that property is hardware model; If true, display model, otherwise display "-"
     * called from Customer Summary, Custom Report and Dealer Customers
     */
    _this.asHardwareModel = function (row, property, hardwareModelName) {
      if (typeof hardwareModelName === "undefined") hardwareModelName = "";
      if (row.hardware_model_name) {
        property = "hardware_model_name";
      }
      var objectValue = _this.getDynamicAttributeValue(row, property);
      var hardwareModelNameValue = _this.getDynamicAttributeValue(
        row,
        hardwareModelName
      );
      if (typeof objectValue === "undefined" || objectValue == null) {
        return "-";
      } else {
        return $filter("control_system_hardware_model")(
          objectValue,
          hardwareModelNameValue
        );
      }
    };

    /**
     * formatDateTimeAsHtml
     * @param {string} rawDateTime The raw date/time value to format.
     * @param {string} format (Optional) The format to use.
     * @returns The formatted date/time HTML block.
     * @description Converts a raw date/time value to a formated HTML block.
     */
    _this.formatDateTimeAsHtml = function (rawDateTime, format) {
      if (
        typeof rawDateTime === "undefined" ||
        rawDateTime == null ||
        rawDateTime == ""
      ) {
        return "-";
      } else if (typeof format === "undefined") {
        // Use a predefined date/time format
        var dateFormat = "mediumDate";
        var timeFormat = "h:mm a";
        return (
          "<div>" +
          $filter("date")(rawDateTime, dateFormat) +
          ' </div><div class="app-user-cell__sub-row" style="font-size: 1.2rem">' +
          $filter("date")(rawDateTime, timeFormat) +
          "</div>"
        );
      } else {
        // Use specified date/time format
        return $filter("date")(rawDateTime, format);
      }
    };

    /**
     * getDynamicAttributeValue
     * @param row
     * @param property
     * @returns {*}
     * @description Splices the given attributes into indexable properties.
     */
    _this.getDynamicAttributeValue = function (row, property) {
      var attributes = property.split(".");
      var objectValue = row;
      if (attributes.length > 1) {
        for (var i = 0; i < attributes.length; i++) {
          if (typeof objectValue === "undefined") return null;
          // Search for brackets, which indicates a bracketed child attribute
          // For example: control_system.services_managers[0].zwave_light_enabled
          var bracketIndex = attributes[i].indexOf("[");
          var closeIndex = attributes[i].indexOf("]");
          if (typeof attributes[i] == "string" && bracketIndex > -1) {
            var attr1 = attributes[i].slice(0, bracketIndex);
            var attr2 = attributes[i].slice(bracketIndex + 1, closeIndex);
            // example: control_systems["services_managers"]
            objectValue = objectValue[attr1];
            // example: control_systems.services_managers[0]
            objectValue = objectValue[attr2];
          } else {
            objectValue = objectValue[attributes[i]];
          }
        }
      } else {
        objectValue = row[property];
      }
      return $sanitize(objectValue);
    };

    /**
     * @ngdoc object
     * @name navToSystemView
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a properly formatted ui-sref directive tag for navigation to the control system view from the grid.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String ui-sref HTML
     *
     */
    _this.navToSystemView = function (
      data,
      customerID,
      controlSystemID,
      accountPrefix,
      labelField,
      siteIdField
    ) {
      let link;
      var customerIDVal = _this.getDynamicAttributeValue(data, customerID);
      var accountPrefixVal = _this.getDynamicAttributeValue(
        data,
        accountPrefix
      );
      var labelFieldVal = _this.getDynamicAttributeValue(data, labelField);
      var controlSystemIDVal = _this.getDynamicAttributeValue(
        data,
        controlSystemID
      );

      let siteId;
      if (siteIdField) {
        siteId = _this.getDynamicAttributeValue(data, siteIdField);
      }

      if (!!siteId) {
        link = `<a class="link link-primary table-emphasis" ui-sref="commType({customer_id:${customerIDVal}, site_id: ${siteId}})" title="${$sanitize(
          labelFieldVal
        )}">${$sanitize(labelFieldVal)}</a>`;
      } else {
        link = `<a class="link link-primary table-emphasis" ui-sref="app.control_system({customer_id:${customerIDVal}, control_system_id: ${controlSystemIDVal}})" title="${$sanitize(
          labelFieldVal
        )}">${$sanitize(labelFieldVal)}</a>`;
      }

      if (
        isUndefinedOrNull(customerIDVal) ||
        isUndefinedOrNull(accountPrefixVal) ||
        isUndefinedOrNull(labelFieldVal) ||
        isUndefinedOrNull(controlSystemIDVal)
      ) {
        return "-";
      } else {
        return UserService.canViewSystems() ? link : labelFieldVal;
      }
    };

    /**
     * @ngdoc object
     * @name navToSystemView
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a properly formatted ui-sref directive tag for navigation to the control system view from the grid.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String ui-sref HTML
     *
     */
    _this.navToSystemViewWithTitle = function (
      data,
      customerID,
      controlSystemID,
      accountPrefix,
      labelField
    ) {
      var customerIDVal = _this.getDynamicAttributeValue(data, customerID);
      var accountPrefixVal = _this.getDynamicAttributeValue(
        data,
        accountPrefix
      );
      var labelFieldVal = _this.getDynamicAttributeValue(data, labelField);
      var controlSystemIDVal = _this.getDynamicAttributeValue(
        data,
        controlSystemID
      );
      var link = `<a class=" link link-primary table-emphasis" title="${$sanitize(
        labelFieldVal
      )}" ui-sref="app.control_system({customer_id:${customerIDVal}, control_system_id: ${controlSystemIDVal}})">${$sanitize(
        labelFieldVal
      )}</a>`;
      if (
        isUndefinedOrNull(customerIDVal) ||
        isUndefinedOrNull(accountPrefixVal) ||
        isUndefinedOrNull(labelFieldVal) ||
        isUndefinedOrNull(controlSystemIDVal)
      ) {
        return "-";
      } else {
        return UserService.canViewSystems() ? link : labelFieldVal;
      }
    };

    /**
     * @ngdoc object
     * @name returnSystemModel
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a properly formatted system model
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String
     *
     */
    _this.returnSystemModel = function (data, systemModel) {
      var systemModelVal = _this.getDynamicAttributeValue(data, systemModel);
      var systemModelString = `${systemModelVal}`;
      if (typeof systemModelVal === "undefined" || systemModelVal == null) {
        return "-";
      } else {
        return systemModelString;
      }
    };

    /**
     * @ngdoc object
     * @name returnFirmware
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a properly formatted firmware version plus the date code and the Firmware Modifier if present
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String
     *
     */
    _this.returnFirmware = function (
      data,
      systemFirmware,
      systemFirmwareDate,
      systemFirmwareModifier
    ) {
      var systemFirmwareVal = _this.getDynamicAttributeValue(
        data,
        systemFirmware
      );
      var systemFirmwareDateVal = _this.getDynamicAttributeValue(
        data,
        systemFirmwareDate
      );
      var systemFirmwareModifierVal = _this.getDynamicAttributeValue(
        data,
        systemFirmwareModifier
      );

      var firmwareAndDate = `<div>${systemFirmwareVal}${systemFirmwareModifierVal}<span class="table-deemphasis"><span ng-if="${systemFirmwareDateVal}"> (</span>${systemFirmwareDateVal}<span ng-if="${systemFirmwareDateVal}">)</span></span></div>`;

      if (data.panels[0].hardware_model === "Video Only") {
        return "-";
      } else {
        return firmwareAndDate;
      }
    };

    _this.renderAccountIdEllipsisCell = function (data, accountID) {
      var accountIDVal = _this.getDynamicAttributeValue(data, accountID);
      var returnedData =
        '<span tooltip="' +
        accountIDVal +
        ' "   tooltip-popup-delay="800">' +
        accountIDVal +
        "</span>";
      return returnedData;
    };

    _this.renderConnectionEllipsisCell = function (data, connectionType) {
      var objectValue = _this.getDynamicAttributeValue(data, connectionType);
      var connectionTypeVal = _this.getDynamicAttributeValue(data, objectValue);
      var returnedData =
        '<div class="cell-ellipsis" ><span tooltip="' +
        connectionTypeVal +
        ' "   tooltip-popup-delay="800">' +
        connectionTypeVal +
        "</span></div>";
      return returnedData;
    };

    /**
     * @ngdoc object
     * @name navToCustomer
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a properly formatted ui-sref directive tag for navigation to the customer summary from the grid.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String ui-sref HTML
     *
     */
    _this.navToCustomerSummary = function (
      data,
      dealerID,
      customerID,
      customerName
    ) {
      var dealerIDVal = _this.getDynamicAttributeValue(data, dealerID);
      var customerIDVal = _this.getDynamicAttributeValue(data, customerID);
      var customerNameVal = _this.getDynamicAttributeValue(data, customerName);
      var link =
        '<a ui-sref="app.customers.customersummary({dealer_id:' +
        dealerIDVal +
        ", customer_id:" +
        customerIDVal +
        '})">' +
        $sanitize(customerNameVal) +
        "</a>";
      return UserService.canViewCustomers() ? link : customerNameVal;
    };

    _this.createTempUserLink = function (panel, linkLabel) {
      var link = `<a  class="da-temp-app-user btn btn-add--no-icon  btn-go-vk pull-right hidden-xs hidden-sm" ng-click="createTempUser( ${panel.id})"> ${linkLabel}</a>`;
      return panel.arming_system !== null || panel.hardware_model === "X001"
        ? link
        : "";
    };

    _this.checkForTechSpptAccessLink = function (panel, linkLabel) {
      const link = `<a  class="da-temp-app-user-preauthorized btn btn-add--no-icon  btn-go-vk pull-right " ng-click="createTempUser( ${panel.id})"> ${linkLabel}</a>`;
      return UserService.isDealerTechnician() &&
        (panel.arming_system !== null || panel.hardware_model === "X001")
        ? link
        : "";
    };

    _this.deleteScheduledUpgradeButton = function (data, id) {
      var idVal = _this.getDynamicAttributeValue(data, id);
      let link = `<a style="cursor: pointer; "  class=" link link-danger  pull-right"  da-confirm-button="deleteUpgrade(${idVal})"  location="left" confirm-append-to-body="true" message="Are you sure you want to delete this schedule?" yes="Confirm" no="Cancel">Cancel</a>`;
      return link;
    };

    //   _this.createMoreMenu = function(data, panel,  dealerID, customerID, customerName, controlSystemID, linkLabel, id, panelName) {
    //   //  var dealerIDVal = _this.getDynamicAttributeValue(data, dealerID);
    //    var customerIdVal = _this.getDynamicAttributeValue(data, customerID);
    //   //  var controlSystemIdVal =  _this.getDynamicAttributeValue(panel, controlSystemID);
    //  //   var customerNameVal = _this.getDynamicAttributeValue(data, customerName);
    //     //    var panelIdVal = _this.getDynamicAttributeValue(panel, id);
    //     var controlSystemIdVal =_this.getDynamicAttributeValue(data, controlSystemID);
    //     var controlSystemNameVal =_this.getDynamicAttributeValue(data, panelName);
    //     var IdVal = _this.getDynamicAttributeValue(data, id);
    //     var panelNameVal = _this.getDynamicAttributeValue(data, panelName);
    //     var dropdownBtn =
    //       `<pre>{{summary.status.isopen}}</pre><div class="dropdown-select pull-right " dropdown dropdown-toggle dropdown-append-to-body>
    //         <span type="button" id="system-dropdown-${controlSystemIdVal}" class="dropdown-select__link dropdown-toggle" ng-disabled="disabled"><i class="icon-more"></i></span>
    //          <!--DROPDOWN MENU-->
    //         <ul class="dropdown-menu dropdown-select__container"  style="transform: translateX(-20rem);" aria-labelledby="system-dropdown-${controlSystemIdVal}" ng-show="summary.status.isopen">
    //             <li class="dropdown-select__item "><a class="dropdown-select__link hidden-md hidden-lg"  ng-if="UserService.canResetAccess()" ng-click="createTempUser(${panel.id})" ><i class="icon-armed_shield_solid " >&nbsp;</i>${linkLabel}</a>
    //             <a class="dropdown-select__link "  ui-sref="app.control_system_edit({customer_id:${customerIdVal},control_system_id:${controlSystemIdVal}})" ng-if="UserService.canEditUsers()"><i class="icon-settings">&nbsp;</i>Edit</a>

    //              <a class="dropdown-select__link " da-service-request control-system-id="${controlSystemIdVal}" control-system-name="${controlSystemNameVal}" ><i class="icon-troubleshooting" >&nbsp;</i>Create Service Request</a>

    //             <a class="dropdown-select__link text-danger" da-confirm-button="summary.deleteSystem(${controlSystemIdVal}); " location="left" message="Deleting control system ${panelNameVal}.  Are you sure?<br>Please note that this will not deactivate the SIM." yes="Delete" no="Cancel" ng-if="UserService.canDeleteUsers()"><span class="text-danger"><i class="icon-trash" >&nbsp;</i>Delete</span></a>
    //             </li>
    //         </ul>
    //       </div>`;
    //     return dropdownBtn;
    //   }

    /**
     * @ngdoc object
     * @name navToMassProgrammingView
     * @methodOf App.controller:MassProgrammingDashboardCtrl
     *
     * @description
     * Returns a properly formatted ui-sref directive tag for navigation to the mass programming job view page.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String ui-sref HTML
     *
     */
    _this.navToMassProgrammingView = function (data, dealerID, jobID) {
      var dealerIDVal = _this.getDynamicAttributeValue(data, dealerID);
      var jobIDVal = _this.getDynamicAttributeValue(data, jobID);
      var link =
        '<a class="link link-primary" ui-sref="app.dealer.mass_programming_view({dealer_id:' +
        dealerIDVal +
        ", job_id:" +
        jobIDVal +
        '})">' +
        "View" +
        "</a>";
      return UserService.canViewCustomers() ? link : jobIDVal;
    };

    /**
     * navToControlSystemEdit
     * @param data
     * @param customerID
     * @param controlSystemID
     * @returns {string}
     * @description navigates user from Customer Summary Page to Control System Edit
     */
    _this.navToControlSystemEdit = function (
      data,
      customerID,
      controlSystemID
    ) {
      var customerIdVal = _this.getDynamicAttributeValue(data, customerID);
      var controlSystemIdVal = _this.getDynamicAttributeValue(
        data,
        controlSystemID
      );
      var link =
        '<a  class="link link-primary" ui-sref="app.control_system_edit({customer_id:' +
        customerIdVal +
        ",control_system_id:" +
        controlSystemIdVal +
        '})"><i class="icon-edit_2"></i></a>';
      return UserService.canEditSystems() ? link : "";
    };

    /**
     * @ngdoc object
     * @name navToPanel
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Returns a properly formatted ui-sref directive tag for navigation to the firmware update page from the grid.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String ui-sref HTML
     *
     */
    _this.navToPanelFirmWareUpdatePage = function (data, userHasBillingTag) {
      var systemName = data.GroupData
        ? angular.fromJson(data.GroupData).control_system_name
        : data.PanelId;
      var link = !userHasBillingTag
        ? '<a class=" link link-primary" ui-sref="app.control_system.remote_update({customer_id:' +
          data.CustomerId +
          ", control_system_id:" +
          data.PanelId +
          '})">' +
          $sanitize(systemName) +
          "</a>"
        : "<span>" + $sanitize(systemName) + "</span>";

      return link;
    };

    _this.createServiceRequest = function (
      data,
      customerId,
      controlSystemID,
      controlSystemName
    ) {
      var controlSystemNameVal = _this.getDynamicAttributeValue(
        data,
        controlSystemName
      );
      var controlSystemIdVal = _this.getDynamicAttributeValue(
        data,
        controlSystemID
      );
      var link = `<a class="link link-primary ng-if="UserService.canViewServiceRequests() && !UserService.enabledSecurityCommand()" icon-troubleshooting icon-link-hover" tooltip="Create Service Request" tooltip-popup-delay="800" da-service-request control-system-id="${controlSystemIdVal}" control-system-name="${controlSystemNameVal}"></a>`;
      return link;
    };

    /**
     * deleteControlSystemButton
     * @param data
     * @param id
     * @param name
     * @returns {string}
     * @description Delete Control System Button (on Customer Summary Page)
     */
    _this.deleteControlSystemButton = function (data, id, name) {
      var idVal = _this.getDynamicAttributeValue(data, id);
      var nameVal = _this.getDynamicAttributeValue(data, name);
      var link = `<a style="cursor: pointer; "class=" link link-danger icon-link-hover icon-trash"  da-confirm-button="summary.deleteSystem(${idVal})" location="left" message="Deleting system ${nameVal}.  Are you sure?<br>Please note that this will not deactivate the SIM." yes="Delete" no="Cancel" tooltip="Delete System" tooltip-popup-delay="800" location="right"></a>`;
      return UserService.canDeleteSystems() ? link : "";
    };

    _this.delete3GScheduledServiceButton = function (data, id, name) {
      var idVal = _this.getDynamicAttributeValue(data, id);
      var nameVal = _this.getDynamicAttributeValue(data, name);
      var link = `<a style="cursor: pointer; "class=" link link-danger icon-link-hover icon-trash"  da-confirm-button="summary.deleteSystem(${idVal})" location="left" message="Deleting system ${nameVal}.  Are you sure?<br>Please note that this will not deactivate the SIM." yes="Delete" no="Cancel" tooltip="Delete System" tooltip-popup-delay="800" location="right"></a>`;
      return UserService.canDeleteSystems() ? link : "";
    };

    /**
     * deleteControlSystemButtonPullRight
     * @param data
     * @param id
     * @param name
     * @returns {string}
     * @description Delete Control System Button (on Customer Summary Page)
     */
    _this.deleteControlSystemButtonPullRight = function (data, id, name) {
      var idVal = _this.getDynamicAttributeValue(data, id);
      var nameVal = _this.getDynamicAttributeValue(data, name);
      var link =
        '<a style="cursor: pointer; "  class=" link link-danger pull-right"  da-confirm-button="summary.deleteSystem(' +
        idVal +
        ')" location="left" message="Deleting system ' +
        nameVal +
        '.  Are you sure?<br>Please note that this will not deactivate the SIM." yes="Delete" no="Cancel" location="right">Delete </a>';
      return UserService.canDeleteSystems() ? link : "";
    };

    /**
     * @ngdoc object
     * @name commType
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Re-formats the raw Communication Type and returns the friendly value.  (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String Re-formatted Communication Type control_system_comm_type
     *
     */
    //TODO: Keep first one rather than second. Make sure to change where used at to data.panel[0]---- same for this one????
    _this.commType = function (row, property) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      if (
        typeof objectValue === "undefined" ||
        objectValue == null ||
        (row.panels && row.panels[0].hardware_model === "Video Only") ||
        row.panels_hardware_model === "Video Only"
      ) {
        return "-";
      } else {
        var connectionTypeVal = $filter("control_system_comm_type")(
          objectValue
        );
        var htmlReturn =
          '<span tooltip="' +
          connectionTypeVal +
          ' "   tooltip-popup-delay="800">' +
          connectionTypeVal +
          "</span>";
        return htmlReturn;
      }
    };

    _this.stringTitlecase = function (row, property) {
      var objectValue = _this.getDynamicAttributeValue(row, property);
      if (
        typeof objectValue === "undefined" ||
        objectValue == null ||
        objectValue === ""
      ) {
        return "-";
      } else {
        return $filter("titleize")(objectValue);
      }
    };

    /**
     * @ngdoc object
     * @name asProgressBar
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Returns a progress bar to the remote update dealer dashboard table.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String bootstrap progress bar  HTML
     *
     */
    _this.asProgressBar = function (data) {
      var progress = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).details.completion_percentage
        : 0;
      var failStatus = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).details.message
        : "Unknown Error";

      var progressBar = "";
      if (JobSchedulerService.isCompleteStatus(data.GroupStatus)) {
        progressBar = "<div><span>Complete</span></div>";
      } else if (JobSchedulerService.isFailedStatus(data.GroupStatus)) {
        progressBar = "<div><span>Failed - " + failStatus + "</span></div>";
      } else if (JobSchedulerService.isPendingStatus(data.GroupStatus)) {
        progressBar = "<div><span>Pending</span></div>";
      } else if (JobSchedulerService.isUnknownStatus(data.GroupStatus)) {
        progressBar = "<div><span>Unknown</span></div>";
      } else {
        progressBar =
          '<div><span><progressbar class="progress-striped active" max="100" value="' +
          progress +
          '"><span style="padding-left: .5em; white-space: nowrap;">' +
          progress +
          "%</span></progressbar></span></div>";
      }

      if (data.PanelId) {
        return progressBar;
      }
    };
    /**
     * @ngdoc object
     * @name massProgrammingAsPercentage
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     * @description
     * Returns the a fraction with the sum of the completed and failed mass programming
     * panel jobs as the numerator and the total panel jobs as the denominatior.
     *
     * @returns String value of the fraction
     */

    _this.massProgrammingAsPercentage = function (data, property) {
      var complete = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).complete
        : 0;
      var failed = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).failed
        : 0;
      var total = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).total
        : 0;
      var percent = "0 %";

      if (total !== 0) {
        var numerator = complete + failed;
        fraction = numerator / total;
        percent = (fraction * 100).toFixed(0) + "%";
      }

      var resultString = "";

      var statusVal = _this.getDynamicAttributeValue(data, property);
      switch (statusVal) {
        case "acquired":
        case "new":
          resultString =
            '<span class="text-info-foreground" style="display: flex; justify-content: flex-start; align-items: center;">Pending</span>';
          break;
        case "started":
        case "running":
          resultString =
            '<span class="text-info-foreground" style="display: flex; justify-content: flex-start; align-items: center;">Running... ' +
            percent +
            "</span>";
          break;
        case "success":
          resultString =
            '<span class="text-success" style="display: flex; justify-content: flex-start; align-items: center;">Complete</span>';
          break;
        case "failed":
          resultString =
            '<span class="text-danger"  style="display: flex; justify-content: flex-start; align-items: center;">Failed </span>';
          break;
        default:
          resultString = statusVal;
          break;
      }
      return resultString;
    };

    _this.getFormattedName = function (data, first_name, last_name) {
      var firstNameVal = _this.getDynamicAttributeValue(data, first_name);
      var lastNameVal = _this.getDynamicAttributeValue(data, last_name);
      return firstNameVal + " " + lastNameVal;
    };

    /**
     * @ngdoc object
     * @name getGroupData
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Returns the data with the given key that is stored in the JobGroup's GroupData field
     *
     * @returns String value for key, or "-" if falsey.
     *
     */
    _this.getGroupData = function (data, key) {
      var groupData = data.GroupData ? angular.fromJson(data.GroupData) : null;
      return groupData && groupData[key] ? $sanitize(groupData[key]) : "-";
    };
    _this.getFirmwareUpdatePath = function (
      data,
      fromFirmwareVersion,
      toFirmwareVersion
    ) {
      var groupData = data.GroupData ? angular.fromJson(data.GroupData) : null;

      return groupData &&
        groupData[fromFirmwareVersion] &&
        groupData[toFirmwareVersion]
        ? $sanitize(
            groupData[fromFirmwareVersion] +
              " - " +
              groupData[toFirmwareVersion]
          )
        : "-";
    };

    _this.nameById = function (data, property) {
      var id = _this.getDynamicAttributeValue(data, property);
      return id;
      //return (typeof propVal === 'undefined' || propVal === null) ? '-' : $sanitize(propVal);
    };

    /**
     * @ngdoc object
     * @name getGroupOutput
     * @methodOf App.controller:MassProgrammingDashboardCtrl
     *
     * @description
     * Returns the data with the given key that is stored in the JobGroup's GroupOutput field
     *
     * @returns String value for key, or "-" if falsey.
     *
     */
    _this.getGroupOutput = function (data, key) {
      var groupOutput = data.GroupOutput
        ? angular.fromJson(data.GroupOutput)
        : null;
      return groupOutput && groupOutput[key]
        ? $sanitize(groupOutput[key])
        : "-";
    };

    /**
     * @ngdoc object
     * @name dashProperty
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns a '-' in place of an undefined data property.  This must be call for any property that might return an
     * 'undefined' value, or the grid raises an error. (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String A '-' when the data is undefined.
     *
     */
    _this.dashProperty = function (data, property) {
      var propVal = _this.getDynamicAttributeValue(data, property);
      return typeof propVal === "undefined" || propVal === null
        ? "-"
        : $sanitize(propVal);
    };

    /**
     * @ngdoc object
     * @name asFirmwareDate
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Re-formats the firmware date.  Changes '-' to '/' so the date can be sorted. (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String Re-formatted Firmware Date
     *
     */
    _this.asFirmwareDate = function (row, property) {
      if (
        typeof row[property] == "undefined" ||
        row[property] == null ||
        row[property].length == 0
      ) {
        return "-";
      } else {
        row[property] = row[property].replace(/-/g, "/");
        return $filter("date")(row[property], "MM/dd/yy");
      }
    };

    /**
     * @ngdoc object
     * @name hasApp
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Looks for the services_manager_full_app_enabled and  field and converts to Yes or No
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String Has App, Arming, Premium, or None
     *
     */
    _this.hasApp = function (row) {
      if (
        typeof row.services_manager_full_app_enabled == "undefined" ||
        row.services_manager_full_app_enabled == null ||
        typeof row.services_manager_arming_app_enabled == "undefined" ||
        row.services_manager_arming_app_enabled == null
      ) {
        return "-";
      } else if (row.services_manager_full_app_enabled) {
        return "Standard";
      } else if (row.services_manager_arming_app_enabled) {
        return "Arming";
      } else {
        return "None";
      }
    };

    /**
     * @ngdoc object
     * @name commCellNumber
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns comm_address field only if comm_type == 'cell'.  Also appends '+' and country code if available.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String Cellular Phone Number with Country Code if available.
     *
     */
    _this.commCellNumber = function (data, property) {
      if (data.panel_comm_type == "cell") {
        return (
          (isUndefinedOrNull(data.panel_country_code)
            ? ""
            : "+" + data.panel_country_code) +
          " " +
          data[property]
        );
      } else {
        return "-";
      }
    };

    /**
     * @ngdoc object
     * @name commIPAddress
     * @methodOf App.controller:DealerCustomers
     *
     * @description
     * Returns comm_address field if comm_type == 'network'.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String IP Address of Control System.
     *
     */
    _this.commIPAddress = function (data, property) {
      if (typeof data[property] == "undefined") {
        return "-";
      } else if (data.panel_comm_type == "network") {
        return data[property];
      } else {
        return "-";
      }
    };

    /**
     * toOrderDetails
     * @param data
     * @returns {string}
     * @description navigates user to Order Details page (called from Store Dashboard)
     */
    _this.toOrderDetails = function (data) {
      var link =
        '<a class="pull-right mr" ng-click="viewOrderDetails(' +
        data.id +
        ')"> Order Details </a>';
      return link;
    };

    /**
     * getShipmentInfo
     * @param data
     * @param key
     * @returns {string}
     * @description gets shipment information (called from Store Dashboard Page)
     */
    _this.getShipmentInfo = function (data, key) {
      var shipmentInfo = data.shipment_info
        ? angular.fromJson(data.shipment_info)
        : null;
      return shipmentInfo && shipmentInfo[key] ? shipmentInfo[key] : "-";
    };

    /**
     * Dynamically apply a specified filter to a column
     * This function allows us to do multiple functions:
     * 1.) Allows us to call to Shipped orders and Created orders.
     * 2.) Allows us to add filters to the columns.
     * @param row
     * @param property
     * @param filterName
     * @returns {*}
     */
    _this.appliedColumnFilters = function (row, property, filterName) {
      var attributes = property.split(".");
      var objectValue = row;
      for (var i = 0; i < attributes.length; i++) {
        if (typeof objectValue == "undefined") return null;
        objectValue = objectValue[attributes[i]];
      }
      if (typeof objectValue) {
        return $filter(filterName)(objectValue);
      }
    };

    /**
     * formatSystemName
     * @param data
     * @returns {*}
     * @description Sanitizes system name for readability (called from Customer Summary Page)
     */
    _this.formatSystemName = function (data) {
      return $sanitize(data.name);
    };

    /**
     * @ngdoc object
     * @name asProgressBar
     * @methodOf App.controller:RemoteUpdateDealerDashboardCtrl
     *
     * @description
     * Returns a progress bar to the remote update dealer dashboard table.
     * (Called from the DTColumnBuilder.newColumn)
     *
     * @returns String bootstrap progress bar  HTML
     *
     */
    var asProgressBar = function (data) {
      var progress = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).details.completion_percentage
        : 0;
      var failStatus = data.GroupOutput
        ? angular.fromJson(data.GroupOutput).details.message
        : "Unknown Error";

      var progressBar = "";
      if (JobSchedulerService.isCompleteStatus(data.GroupStatus)) {
        progressBar = "<div><span>Complete</span></div>";
      } else if (JobSchedulerService.isFailedStatus(data.GroupStatus)) {
        progressBar = "<div><span>Failed - " + failStatus + "</span></div>";
      } else if (JobSchedulerService.isPendingStatus(data.GroupStatus)) {
        progressBar = "<div><span>Pending</span></div>";
      } else if (JobSchedulerService.isUnknownStatus(data.GroupStatus)) {
        progressBar = "<div><span>Unknown</span></div>";
      } else {
        progressBar =
          '<div><span><progressbar class="progress-striped active" max="100" value="' +
          progress +
          '"><span style="padding-left: .5em; white-space: nowrap;">' +
          progress +
          "%</span></progressbar></span></div>";
      }
      if (data.PanelId) {
        return progressBar;
      }
    };
  },
]);
