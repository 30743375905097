import graphql from "babel-plugin-relay/macro";
import NakedButton from "common/components/web/Button/NakedButton";
import Spinner from "components/CameraTable/Spinner";
import { useShowAlert } from "contexts/AlertsContext";
import React, { useEffect, useRef } from "react";
import { useMutation } from "react-relay";
import { VideoDeviceStatus } from "securecom-graphql/client/schema-types";
import styled from "styled-components";
import { AppName } from "utils/types";
import { getRegionColor } from "./utils";
import { CameraEditStyledComponentsReactivateMutation } from "./__generated__/CameraEditStyledComponentsReactivateMutation.graphql";
import { CameraEditStyledComponentsRefreshMutation } from "./__generated__/CameraEditStyledComponentsRefreshMutation.graphql";

export const Grid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 3fr 3fr;
  grid-template-columns: 1fr 1f 1fr 2fr;
`;
export const Header = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
`;
export const MainEdit = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
  grid-row-start: 1;
  grid-row-end: end;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
`;
export const InnerHeader = styled.div`
  grid-column-start: 1;
  grid-column-end: end;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  margin-left: 4rem;
  margin-bottom: 1.5rem;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;
export const InputLabel = styled.span`
  padding-right: 2rem;
  white-space: nowrap;
  width: 150px;
`;
export const InputContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 2rem;
`;
export const MainSettingsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
export const MainContainerHeading = styled.div`
  padding-top: 1.5rem;
  border-bottom: 1px solid lightgray;
  grid-column-start: 1;
  grid-column-end: 2;
  color: #5aa4c4;
  font-size: 2.5rem;
  font-weight: bold;
`;
export const MainSettings = styled.div`
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 45rem;
`;
export const DetectionContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  padding-left: var(--measure-3x);
`;
export const SnapshotFrame = styled.div<{ aspectRatio: number }>`
  position: sticky;
  top: 80px;
  width: 100%;
  height: 0;
  padding-top: ${({ aspectRatio }) => 100 / aspectRatio}%;
`;
export const SnapshotContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const Snapshot = styled.img`
  display: block;
  width: 100%;
  position: relative;
`;
export const AnalyticsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0rem;
`;
export const SettingsTitles = styled.div`
  font-weight: bold;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction: column;
`;
export const TagContainer = styled.div`
  display: flex;
`;
export const ModalInputContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 2rem;
`;
export const CameraDetailsContainer = styled.div`
  @media (min-width: 768px) {
    margin-left: auto;
  }
`;
export const PriceOption = styled.div`
  border: solid;
  border-color: darkgray;
  border-radius: 4px;
  background-color: lightgray;
  margin: 1rem 0rem;
`;
export const PriceContainer = styled.div`
  display: flex;
`;

interface RecorderCameraEditVideoNotViewableProps {
  cameraId?: string;
  isEditable?: boolean;
  isSupervisor?: boolean;
  status?: VideoDeviceStatus;
  vernaculars?: {
    dealerId?: number;
    original?: string;
    replacement?: string;
    scapiId?: number;
  }[];
  appName: AppName;
}
export const RecorderCameraEditVideoNotViewable = (
  props: RecorderCameraEditVideoNotViewableProps
) => {
  const {
    cameraId,
    isEditable,
    isSupervisor,
    status = VideoDeviceStatus.ACTIVE,
    vernaculars,
    appName,
  } = props;
  const showAlert = useShowAlert();
  const [reactivate, isReactivating] =
    useMutation<CameraEditStyledComponentsReactivateMutation>(
      reactivateMutation
    );
  const [refresh] =
    useMutation<CameraEditStyledComponentsRefreshMutation>(refreshMutation);
  const currentStatus = useRef(status); // Need to use a ref here so when it is updated from refreshing, the updated status can be compared in useEffect to terminate additional polling.
  const pollingInterval = 2000;
  const handleReactivate = () => {
    if (cameraId) {
      reactivate({
        variables: {
          id: cameraId,
        },
        onCompleted: (result) => {
          const { secureComCamera, error, message } =
            result.reactivateSecureComCamera;
          if (error && message) {
            showAlert({
              type: "error",
              text: message,
            });
          } else if (secureComCamera && message) {
            showAlert({
              type: "success",
              text: message,
            });
            currentStatus.current = VideoDeviceStatus[secureComCamera.status];
          } else {
            showAlert({
              type: "error",
              text: "Failed to reactivate.",
            });
          }
        },
      });
    }
  };

  const appUsersVern = vernaculars?.find((v) => v.original === "app users");
  const customersVern = vernaculars?.find((v) => v.original === "customers");

  useEffect(() => {
    type Timer = ReturnType<typeof setTimeout>;
    const refreshPolls: Array<Timer> = [];
    if (cameraId && status === VideoDeviceStatus.ACTIVATING) {
      refreshPolls.push(
        setTimeout(function keepAlive() {
          refresh({
            variables: {
              id: cameraId,
            },
            onCompleted: (result) => {
              const { secureComCamera } = result.refreshSecureComCamera;

              if (secureComCamera) {
                currentStatus.current =
                  VideoDeviceStatus[secureComCamera.status];
              }
            },
          });
          // Keep on refreshing status while status is activating
          if (currentStatus.current === VideoDeviceStatus.ACTIVATING) {
            refreshPolls.push(setTimeout(keepAlive, pollingInterval));
          }
        }, pollingInterval)
      );
    }
    return () => {
      refreshPolls.forEach((id) => clearTimeout(id));
    };
  }, [status]);

  const videoNotViewableBody = () => {
    switch (status) {
      case VideoDeviceStatus.ACTIVATING:
        return (
          <>
            <VideoNotViewableTitle>
              Camera is Currently Activating
            </VideoNotViewableTitle>
            <CssSpinner className="csspinner traditional" />
          </>
        );
      case VideoDeviceStatus.ERROR:
      case VideoDeviceStatus.INACCESSIBLE:
        return (
          <>
            <VideoNotViewableTitle>
              Camera Failed to Activate
            </VideoNotViewableTitle>
            <StyledButton
              type="button"
              className="btn btn-default btn-sm"
              disabled={!isEditable || isReactivating}
              onClick={handleReactivate}
            >
              {isReactivating && <LoadingSpinner />}
              <ButtonText isLoading={isReactivating}>Reactivate</ButtonText>
            </StyledButton>
          </>
        );
      default:
        return (
          <>
            <VideoNotViewableTitle>
              {customersVern ? customersVern.replacement : "Customer"} Video is
              Not Viewable at this Time
            </VideoNotViewableTitle>
            {isSupervisor ? (
              <VideoNotViewableMessage>
                Supervisor level accounts have no access to videos. To view
                videos, please switch to a dealer or{" "}
                {customersVern ? customersVern.replacement : "customer"} level
                account.
              </VideoNotViewableMessage>
            ) : (
              <>
                <VideoNotViewableMessage>
                  To view{" "}
                  {customersVern ? customersVern.replacement : "customer"}{" "}
                  video, access needs to be granted by an Admin-level{" "}
                  {appUsersVern ? appUsersVern.replacement : "App User"}.
                  Contact your{" "}
                  {customersVern ? customersVern.replacement : "customer"} and
                  instruct them to allow video access:
                </VideoNotViewableMessage>
                <VideoNotViewableInstruction>
                  1. Open Virtual Keypad settings and navigate to Video Privacy
                  <br />
                  2. Tap "Grant Access" to allow {appName} to view video for 12
                  hours
                </VideoNotViewableInstruction>
              </>
            )}
          </>
        );
    }
  };

  return (
    <VideoNotViewableContainer>
      {videoNotViewableBody()}
    </VideoNotViewableContainer>
  );
};
interface SnapshotLoadingScreenProps {
  failed: boolean;
  handleRefresh: () => void;
}
export const SnapshotLoadingScreen = (props: SnapshotLoadingScreenProps) => {
  const { failed, handleRefresh } = props;
  return (
    <VideoNotViewableContainer>
      {failed ? (
        <>
          <VideoNotViewableTitle>
            Snapshot failed to load.
          </VideoNotViewableTitle>
          <button
            type="button"
            className="btn btn-default btn-sm"
            onClick={handleRefresh}
          >
            Refresh
          </button>
        </>
      ) : (
        <div className="csspinner traditional" />
      )}
    </VideoNotViewableContainer>
  );
};
const reactivateMutation = graphql`
  mutation CameraEditStyledComponentsReactivateMutation($id: ID!) {
    reactivateSecureComCamera(id: $id) {
      ... on ReactivateSecureComCameraSuccessPayload {
        secureComCamera {
          id
          status
        }
        message
      }
      ... on ReactivateSecureComCameraErrorPayload {
        error
        message
      }
    }
  }
`;
const refreshMutation = graphql`
  mutation CameraEditStyledComponentsRefreshMutation($id: ID!) {
    refreshSecureComCamera(id: $id) {
      ... on RefreshSecureComCameraSuccessPayload {
        secureComCamera {
          id
          snapshot
          status
        }
        message
      }
      ... on RefreshSecureComCameraErrorPayload {
        error
        message
      }
    }
  }
`;
const VideoNotViewableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: lightgrey;
  min-width: 200px;
  width: 100%;
  aspect-ratio: 16 / 9;
  margin-bottom: 3rem;
`;
const VideoNotViewableTitle = styled.span`
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 1rem 0.5rem 1rem;
`;
const VideoNotViewableMessage = styled.span`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  width: 70%;
`;
const VideoNotViewableInstruction = styled.span`
  font-size: 1.5rem;
  padding-left: 2rem;
  width: 70%;
`;
const CssSpinner = styled.div`
  height: 5rem;
`;
const StyledButton = styled.button`
  position: relative;
`;
const LoadingSpinner = styled(Spinner)`
  position: absolute;
  margin: auto;
  inset: 0;
`;
const ButtonText = styled.span<{ isLoading: boolean }>`
  visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
`;

export const RegionsSettingsRoot = styled.section`
  padding: var(--measure-2x);

  &:not(:first-child) {
    border-top: 1px solid var(--color-neutral-400);
  }
`;
export const RegionsSettingsTable = styled.table`
  width: 100%;
  border: none;
  background-color: transparent;
`;
export const RegionsSettingsRow = styled.tr`
  border: none;
  width: 100%;
`;
export const RegionSettingsHeaderText = styled.h1<{ index: number }>`
  margin-top: 0;
  font-weight: bold;
  font-size: var(--measure-font-18);

  &::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 9999px;
    margin-right: var(--measure-1x);
    background-color: ${({ index }) => getRegionColor(index)};
    vertical-align: middle;
    line-height: 1;
  }
`;
export const RegionSettingsDeleteButton = styled(NakedButton)`
  color: var(--color-danger-300);

  &:hover {
    color: var(--color-danger-700);
  }
`;
const Cell = styled.td`
  display: table-cell;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  vertical-align: middle;
`;
export const FieldLabelCell = styled(Cell)`
  padding-right: var(--measure-2x);
  padding-left: 0;
  width: 115px;
`;
export const FieldLabelContainer = styled.span`
  display: flex;
  align-items: center;
  vertical-align: middle;
  min-height: 3.6rem;
`;
export const FieldValueCell = styled(Cell)`
  padding-left: 0;
  padding-right: 0;
  width: auto;
`;
export const FieldLabelText = styled.label`
  white-space: nowrap;
  margin: 0;
  font-weight: 700;
`;
export const SensitivityValue = styled.div`
  text-align: center;
  font-weight: bold;
`;
export const Svg = styled.svg`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
`;
